import * as React from "react"

import { create } from "lib/store"

import { toggleItem } from "helpers/array"
import { usePrevious } from "helpers/hooks"

export const useExpandableHeaders = create((set) => ({
  expandedHeaderIds: [],
  setExpandedIds: (ids) => set({ expandedHeaderIds: ids }),
  toggleExpandedId: (id) =>
    set((state) => ({
      expandedHeaderIds: toggleItem(state.expandedHeaderIds, id),
    })),
}))

const expandedHeaderIdsSelector = (state) => state.expandedHeaderIds

const isSelectedSelector = (headerId) => (state) =>
  state.expandedHeaderIds.includes(headerId)

const setSelector = (state) => state.setExpandedIds

const toggleSelector = (state) => state.toggleExpandedId

export function useIsExpanded(headerId) {
  let isExpanded = useExpandableHeaders(isSelectedSelector(headerId))
  let toggleExpandedId = useExpandableHeaders(toggleSelector)

  function toggleIsExpanded(expand) {
    if (expand && isExpanded) return // Do nothing
    if (!expand && !isExpanded) return // Do nothing

    toggleExpandedId(headerId)
  }

  return [isExpanded, toggleIsExpanded]
}

export function useExpandHeader() {
  let toggleExpandedId = useExpandableHeaders(toggleSelector)
  let currentExpandedIds = useExpandableHeaders(expandedHeaderIdsSelector)

  let expandHeader = (headerId) =>
    !currentExpandedIds.includes(headerId) && toggleExpandedId(headerId)

  return [expandHeader]
}

export function useIsAllExpanded(headerIds) {
  let prevHeaderIds = usePrevious(headerIds)
  let currentExpandedIds = useExpandableHeaders(expandedHeaderIdsSelector)
  let isAllExpanded = headerIds.every((id) => currentExpandedIds.includes(id))
  let setExpandedIds = useExpandableHeaders(setSelector)

  React.useEffect(() => {
    if (
      (!prevHeaderIds || prevHeaderIds.length === 0) &&
      headerIds.length > 0
    ) {
      setExpandedIds(headerIds)
    }
  }, [headerIds])

  function toggleExpandAll(expand) {
    setExpandedIds(expand ? headerIds : [])
  }

  return [isAllExpanded, toggleExpandAll]
}
