import * as React from "react"

import { create, shallow } from "lib/store"
import camalize from "lib/camel-case"

import { getClosingBinder } from "helpers/api"

const useBuildWatch = create((set) => ({
  binderId: null,
  data: null,
  error: null,
  status: "IDLE",
  clear: () => set(() => ({ status: "IDLE", data: null, error: null })),
  setData: (data) =>
    set((state) => ({
      data,
      status: data.currentStage === "complete" ? "COMPLETE" : state.status,
    })),
  setError: (error) => set(() => ({ status: "ERROR", error })),
  checkForBuild: (binderId) =>
    set(() => ({ binderId, data: null, error: null, status: "LOADING" })),
  triggerBuildWatch: (binderId) =>
    set(() => ({ binderId, status: "WATCHING" })),
}))

export function useTriggerBuildWatch() {
  return useBuildWatch(
    (state) => ({
      triggerBuildWatch: state.triggerBuildWatch,
      status: state.status,
    }),
    shallow
  )
}

const statusSelector = (state) => ({
  binderId: state.binderId,
  data: state.data,
  status: state.status,
  checkForBuild: state.checkForBuild,
  clear: state.clear,
  setData: state.setData,
  setError: state.setError,
  triggerBuildWatch: state.triggerBuildWatch,
})

const decodeBuildData = ({ build_status }) =>
  Object.keys(build_status).reduce((obj, key) => {
    obj[camalize(key)] = build_status[key]
    return obj
  }, {})

export function useBuildWatchStatus() {
  let {
    data,
    status,
    checkForBuild,
    clear,
    binderId,
    setData,
    setError,
    triggerBuildWatch,
  } = useBuildWatch(statusSelector, shallow)

  React.useEffect(() => {
    let loader = async () => {
      try {
        let data = decodeBuildData(await getClosingBinder(binderId))
        if (
          !["idle", "complete", "error", undefined].includes(data.currentStage)
        ) {
          setData(data)
          triggerBuildWatch(binderId)
        } else {
          clear()
        }
      } catch (e) {
        clear()
      }
    }
    if (status === "LOADING") {
      loader()
    }
  }, [binderId, status])

  React.useEffect(() => {
    let query = async () => {
      if (status === "WATCHING") {
        try {
          let data = decodeBuildData(await getClosingBinder(binderId))
          if (data.currentStage === "error") {
            setError(data)
            return
          }
          setData(data)
        } catch (e) {
          setError(e)
        }
      }
    }
    let poller = setInterval(query, 1000)

    return () => clearInterval(poller)
  }, [status, binderId])

  return { status, clear, data, checkForBuild }
}
