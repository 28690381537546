import * as React from "react"

import EditIndexFormatIcon from "icons/edit-index-format.svg"

import SAModal from "components/modals/SAModal"

import BinderIndexFormatForm from "../BinderIndexFormatForm"

type BinderIndexFormatModalProps = {
  binder?: Record<string, any>
  onClose?: () => void
}

export default function BinderIndexFormatModal({
  binder,
  onClose = () => null,
}: BinderIndexFormatModalProps) {
  return (
    <SAModal
      icon={<EditIndexFormatIcon className="h-6 w-6" viewBox="0 0 24 18" />}
      onClose={onClose}
      title="Binder Index: Styles and formats"
      body={
        <div className="w-[380px]">
          <BinderIndexFormatForm
            binder={binder}
            onCancel={onClose}
            onSuccess={onClose}
          />
        </div>
      }
    />
  )
}
