import * as React from "react"

import { Icon, Spinner, Switch } from "@blueprintjs/core"
import { Tooltip2, MenuItem2 as MenuItem } from "@blueprintjs/popover2"

import { useUpdateBinderField } from "features/closing-binders/api"

type MenuItemToggleProps = {
  binderId: string
  label: string
  field: string
  isChecked?: boolean
  toolTipContent?: string
}

export default function MenuItemToggle({
  binderId,
  label,
  field,
  isChecked = false,
  toolTipContent,
}: MenuItemToggleProps) {
  let { updateBinderField, isLoading } = useUpdateBinderField()

  let onChange = () => updateBinderField(binderId, { [field]: !isChecked })

  return (
    <MenuItem
      text={
        <div
          className="flex items-center"
          onClick={(evt) => evt.stopPropagation()}
        >
          {toolTipContent ? (
            <Tooltip2
              openOnTargetFocus={false}
              className="mr-2"
              content={toolTipContent}
            >
              <Icon icon="info-sign" size={12} className="flex items-center" />
            </Tooltip2>
          ) : (
            <Icon icon="info-sign" size={12} className="mr-2" />
          )}
          <div>{label}</div>
          <div className="ml-auto flex items-center">
            <div className="w-5">{isLoading && <Spinner size={14} />}</div>
            <Switch className="m-0" checked={isChecked} onChange={onChange} />
          </div>
        </div>
      }
    />
  )
}
