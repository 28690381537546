import { InputGroup as Input } from "@blueprintjs/core"

import {
  ChangeEvent,
  KeyboardEvent,
  MouseEvent,
  useEffect,
  useRef,
  useState,
} from "react"

import { useFocusInput } from "helpers/hooks"

type EditableTextProps = {
  isEditing?: boolean
  id?: string
  text?: string
  onSave?: (name: string) => void
  onCancel?: () => void
  placeholderText?: string
}

export default function EditableText({
  id,
  text,
  onSave = () => null,
  isEditing,
  onCancel = () => null,
  placeholderText = "Enter a document name",
}: EditableTextProps) {
  const [editing, setEditing] = useState(isEditing)
  const [value, setValue] = useState(text)
  const ref = useRef<HTMLDivElement>(null)
  const { inputRef } = useFocusInput()

  useEffect(() => {
    setValue(text)
  }, [text])

  const handleParentClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()

    setEditing(true)
  }

  const handleEdit = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.metaKey || e.ctrlKey) {
      // stop CMD/CTRL+A from bubbling up when on a page with hot keys, ie Transaction Dashboard
      e.stopPropagation()
    }
    if (e.key === "Enter") {
      handleConfirm()
    }

    if (e.key === "Escape") {
      e.stopPropagation()
      setValue(text)
      setEditing(false)
      onCancel()
    }
  }

  const handleConfirm = () => {
    // don't persist blank edits
    if (value === "" || !value) {
      setValue(text)
      return
    }

    onSave(value)
    setEditing(false)
    onCancel()
  }

  return (
    <div
      className="inline-flex w-full"
      onClick={handleParentClick}
      ref={ref}
      key={id}
    >
      {editing ? (
        <Input
          inputRef={inputRef}
          draggable
          onDragStart={(e) => {
            e.preventDefault()
            e.stopPropagation()
            console.log("DRAG STOPPED")
          }}
          value={value}
          onChange={handleEdit}
          onBlur={handleConfirm}
          onKeyDown={handleKeyDown}
          maxLength={350}
          fill
          style={{
            height: "28px",
            lineHeight: "28px",
            WebkitUserSelect: "text",
          }}
          placeholder={placeholderText}
        />
      ) : (
        <div className="truncate">{text}</div>
      )}
    </div>
  )
}
