// Due to a race condition when deleting multiple items, It's possible to end
// up with a content array that contains references to items that no longer
// exist. This function will remove those references.
function removeInvalidItems(content = [], items) {
  let itemIds = items.map((item) => item.id)

  let result = []

  for (const node of content) {
    if (node.type === "HEADER") {
      result.push({
        ...node,
        content: removeInvalidItems(node.content, items),
      })
      continue
    }

    if (itemIds.includes(node.id)) {
      result.push(node)
    }
  }

  return result
}

export default function scrubInvalidContent(content = [], data = {}) {
  return removeInvalidItems(content, data.items || [])
}
