// @refresh reset
import * as React from "react"

import { Button, Icon, Position, Menu, Intent } from "@blueprintjs/core"
import { Popover2, MenuItem2 as MenuItem } from "@blueprintjs/popover2"
import renderPDF from "lib/pdf/render"

type PagePreviewsProps = {
  onClose?: () => void
  coverPageUrl: string
  pdfUrl?: string
  wordUrl?: string
  workingGroupPageUrl?: string
}

export default function PagePreviews({
  coverPageUrl = "",
  pdfUrl = "",
  wordUrl = "",
  workingGroupPageUrl = "",
  onClose = () => null,
}: PagePreviewsProps) {
  let pdfView = React.useRef<HTMLCanvasElement | null>(null)
  let scrollView = React.useRef(null)
  let [currentPage, setCurrentPage] = React.useState(1)
  let [pdfViewer, setPdfViewer] = React.useState(null)
  let [pdfError, setPdfError] = React.useState(null)
  let hasPrevPage = currentPage > 1
  let hasNextPage = currentPage < (pdfViewer?.totalPages || 1)

  React.useEffect(() => {
    async function initialize() {
      let fileUrls = workingGroupPageUrl
        ? [coverPageUrl, workingGroupPageUrl, pdfUrl]
        : [coverPageUrl, pdfUrl]

      try {
        let viewer = await renderPDF({
          viewerElement: pdfView.current,
          fileUrls: fileUrls,
        })
        setPdfViewer(viewer)
      } catch (e) {
        console.log(e)
        setPdfError(e)
      }
    }
    initialize()
  }, [])

  React.useEffect(() => {
    pdfViewer?.renderPage(currentPage)
  }, [currentPage, pdfViewer])

  if (pdfError) {
    return (
      <div className="flex h-full w-full flex-col items-center justify-center">
        <Icon icon="warning-sign" size={48} intent={Intent.DANGER} />
        <p className="mt-4 px-8 text-center">
          There was a problem rendering the preview file. Please try again or
          reach out to support.
        </p>
      </div>
    )
  }

  return (
    <div className="relative h-full w-full">
      <div
        className="h-full w-full overflow-hidden"
        id="index-preview-wrapper"
        ref={scrollView}
      >
        <canvas ref={pdfView} className="z-0 h-full w-full" />
      </div>
      {pdfViewer && (
        <div className="absolute inset-0 z-50 grid h-full grid-rows-[50px,1fr,50px]">
          <div className="flex items-start">
            <Button
              className="m-1 ml-auto"
              icon="cross"
              minimal
              onClick={onClose}
            />{" "}
          </div>
          <div className="flex items-center px-4">
            {hasPrevPage && (
              <Button
                className=""
                minimal
                icon={<Icon icon="circle-arrow-left" size={32} />}
                onClick={() => setCurrentPage((p) => p - 1)}
              />
            )}
            {hasNextPage && (
              <Button
                className="ml-auto"
                minimal
                icon={<Icon icon="circle-arrow-right" size={32} />}
                onClick={() => setCurrentPage((p) => p + 1)}
              />
            )}
          </div>
          <div className="flex items-center">
            <Popover2
              className="mx-auto"
              content={
                <Menu className="min-w-[6rem]">
                  <MenuItem
                    rel="noopener noreferrer"
                    href={pdfUrl}
                    target="_blank"
                    text="PDF Index"
                  />
                  <MenuItem
                    rel="noopener noreferrer"
                    href={wordUrl}
                    target="_blank"
                    text="Word Index"
                  />
                </Menu>
              }
              minimal
              position={Position.BOTTOM}
            >
              <Button text="Download" icon="download" />
            </Popover2>
          </div>
        </div>
      )}
    </div>
  )
}
