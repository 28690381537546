import * as React from "react"

import {
  formatClosingBinderStructureToAPI,
  formatClosingBinderContentToAPI,
} from "app/api/graphql/resolvers/formatters"

import useToaster from "helpers/toaster"
import { updateUser } from "helpers/api"
import { usePrevious } from "helpers/hooks"

import applyMarkersToContent from "features/closing-binders/apply-markers"
import { useCurrentUser } from "features/auth/withAuth"
import { encodeContent } from "features/closing-binders/api"
import { useSaveClosingBinderForm } from "features/closing-binders/save-form"

function useUpdateUserField() {
  let [isSaving, setIsSaving] = React.useState(false)
  let [error, setError] = React.useState<Error | null>(null)
  let { currentUser, refreshUser } = useCurrentUser()

  async function updateUserField(fields) {
    setIsSaving(true)
    try {
      if (!currentUser) {
        throw new Error("No user found")
      }
      await updateUser({ ...fields, uuid: currentUser?.uuid })
      refreshUser?.()
    } catch (e) {
      if (e instanceof Error) {
        setError(e)
      } else if (
        e &&
        typeof e === "object" &&
        "message" in e &&
        typeof e.message === "string"
      ) {
        setError(new Error(e.message))
      } else {
        setError(new Error("An error occurred while saving the user fields"))
      }
    }
    setIsSaving(false)
  }

  return { isLoading: isSaving, updateUserField, error }
}

export default function useSubmit(binder, options = {}) {
  let { failure } = useToaster()
  let {
    save: saveBinder,
    isLoading,
    error,
  } = useSaveClosingBinderForm({
    activeBinderId: binder.id,
  })
  let {
    updateUserField,
    isLoading: isSavingUser,
    error: error2,
  } = useUpdateUserField()
  let isAllLoading = isLoading || isSavingUser
  let prevIsAllLoading = usePrevious(isAllLoading)

  React.useEffect(() => {
    if (!error && !error2 && !isAllLoading && prevIsAllLoading) {
      options.onSuccess?.()
    }
  }, [error, error2, isAllLoading, prevIsAllLoading])

  React.useEffect(() => {
    if (error || error2) {
      failure(
        "We ran into a problem saving the form. Please try again or reach out to support."
      )
    }
  }, [error, error2])

  function handleSubmit(values) {
    return (evt) => {
      evt.preventDefault()

      saveBinder({
        ...binder,
        formatStructure: values,
        content: formatClosingBinderContentToAPI(
          encodeContent(applyMarkersToContent(binder.content, values))
        ),
      })
      if (values.saveAsDefault) {
        updateUserField({
          binder_format_structure: formatClosingBinderStructureToAPI(values),
        })
      }
    }
  }

  return { handleSubmit, isLoading: isLoading || isSavingUser }
}
