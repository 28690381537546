import PDFJS from "lib/pdf"
import { PDFDocumentProxy } from "pdfjs-dist"

export function getDocumentForPage(
  pageNum: number,
  pdfDocuments: PDFDocumentProxy[]
) {
  let prevPagesCount = 0

  let pdfDoc = pdfDocuments.find((pdfDoc) => {
    prevPagesCount += pdfDoc.numPages

    return pageNum <= prevPagesCount
  })!

  return {
    prevPagesCount,
    pdfDoc,
    currentPage: pageNum - (prevPagesCount - pdfDoc.numPages),
  }
}

export default async function render({
  viewerElement,
  fileUrls,
}: { viewerElement?: HTMLCanvasElement; fileUrls?: string[] } = {}) {
  if (!viewerElement || !fileUrls) {
    throw new Error("All parameters are required to render PDF")
  }

  let pdfDocuments = await Promise.all(
    fileUrls.map((url) => PDFJS.getDocument(url).promise)
  )

  let totalPages = pdfDocuments.reduce((total, doc) => total + doc.numPages, 0)

  let renderPage = async (pageNum: number) => {
    let { pdfDoc, currentPage } = getDocumentForPage(pageNum, pdfDocuments)
    let page = await pdfDoc.getPage(currentPage)
    const dpr = window.devicePixelRatio
    const viewport = page.getViewport({ scale: dpr })
    const canvasContext = viewerElement.getContext("2d")
    viewerElement.width = viewport.width + dpr
    viewerElement.height = viewport.height + dpr

    if (canvasContext) {
      await page.render({
        canvasContext,
        viewport,
      }).promise
    }
  }

  return { totalPages, renderPage }
}
