import { type APIClosingBinderContent } from "./ClosingBinderContent"
import {
  type APIClosingDates,
  type ClosingDates,
  decode as decodeClosingDates,
} from "./ClosingDate"
import {
  type APIClosingBinderFormatStructure,
  type ClosingBinderFormatStructure,
  decode as decodeFormatStructure,
} from "./ClosingBinderFormatStructure"

export type APIClosingBinder = {
  uuid: string
  name: string
  nickname: string
  client_name: string
  party_names: string[]
  closing_dates: APIClosingDates
  content: APIClosingBinderContent[]
  binder_type: string
  template: string
  custom_cover_page: string
  working_group_page: string
  format_structure: APIClosingBinderFormatStructure
  has_slip_pages: boolean
  has_attachment_bookmarks: boolean
  has_back_to_index_buttons: boolean
  has_attachment_cross_references: boolean
}

export type ClosingBinder = {
  id: string
  title: string
  transactionId: string
  nickname: string
  clientName: string
  partyNames: string[]
  pdfBookmarks?: string[]
  slipPage?: string
  closingDates: ClosingDates
  content: APIClosingBinderContent[]
  crossReferences?: string
  binderType: string
  templateId: string
  customCoverPageId: string | null
  workingGroupDocumentId: string | null
  formatStructure: ClosingBinderFormatStructure
  hasSlipPages: boolean
  hasAttachmentBookmarks: boolean
  hasBackToIndexButtons: boolean
  hasAttachmentCrossReferences: boolean
}

export const decode = ({
  uuid: id,
  name: title,
  nickname,
  client_name: clientName,
  party_names: partyNames,
  closing_dates: closingDates,
  content,
  binder_type: binderType,
  template: templateId,
  custom_cover_page: customCoverPageId,
  working_group_page: workingGroupPageId,
  format_structure,
  has_slip_pages: hasSlipPages,
  has_attachment_bookmarks: hasAttachmentBookmarks,
  has_back_to_index_buttons: hasBackToIndexButtons,
  has_attachment_cross_references: hasAttachmentCrossReferences,
}: APIClosingBinder): ClosingBinder => ({
  id,
  binderType,
  clientName,
  closingDates: decodeClosingDates(closingDates),
  content,
  customCoverPageId,
  workingGroupDocumentId: workingGroupPageId,
  nickname,
  partyNames,
  title,
  templateId,
  formatStructure: decodeFormatStructure(format_structure),
  hasSlipPages,
  hasAttachmentBookmarks,
  hasBackToIndexButtons,
  hasAttachmentCrossReferences,
})
