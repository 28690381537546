import { APIClosingBinder, ClosingBinder } from "models/ClosingBinder"

const formatClosingDateCategoryFromAPI = (category) => {
  const map = {
    closing: "closing",
    sign_and_close: "signAndClose",
    multiple: "multipleClosing",
  }

  return map[category]
}

export const formatClosingBinderStructureFromAPI = ({
  index_style: indexStyle = "table_of_contents",
  primary_header: primaryHeader = "uppercase_roman",
  second_header: secondHeader = "uppercase_alphabetic",
  third_header: thirdHeader = "lowercase_roman",
  last_documents: documentsHeader = "lowercase_alphabetic",
  numbering: docNumbering = "tabs",
  reset_tabs: resetTabs = false,
} = {}) => ({
  indexStyle,
  primaryHeader,
  secondHeader,
  thirdHeader,
  docNumbering,
  documentsHeader,
  resetTabs,
})

export const formatClosingBinderFromAPI = ({
  uuid: id,
  name: title,
  nickname,
  client_name: clientName,
  party_names: partyNames,
  closing_dates: closingDates,
  content,
  binder_type: binderType,
  template: templateId,
  custom_cover_page: customCoverPageId,
  working_group_page: workingGroupDocumentId,
  format_structure,
  has_slip_pages: hasSlipPages,
  has_attachment_bookmarks: hasAttachmentBookmarks,
  has_back_to_index_buttons: hasBackToIndexButtons,
  has_attachment_cross_references: hasAttachmentCrossReferences,
}) => ({
  id,
  binderType,
  clientName,
  closingDates: {
    category: formatClosingDateCategoryFromAPI(closingDates.category),
    closingDate: closingDates.closing_date,
    signingDate: closingDates.signing_date,
  },
  content,
  customCoverPageId,
  workingGroupDocumentId,
  nickname,
  partyNames,
  title,
  templateId,
  formatStructure: formatClosingBinderStructureFromAPI(format_structure),
  hasSlipPages,
  hasAttachmentBookmarks,
  hasBackToIndexButtons,
  hasAttachmentCrossReferences,
})

const formatClosingDateCategoryToAPI = (category) => {
  const map = {
    closing: "closing",
    signAndClose: "sign_and_close",
    multipleClosing: "multiple",
  }

  return map[category]
}

export const formatClosingBinderStructureToAPI = (input) => {
  if (!input) return

  let {
    indexStyle: index_style,
    primaryHeader: primary_header,
    secondHeader: second_header,
    thirdHeader: third_header,
    docNumbering: numbering,
    documentsHeader: last_documents,
    resetTabs: reset_tabs,
  } = input
  return {
    index_style,
    primary_header,
    second_header,
    third_header,
    last_documents,
    numbering,
    reset_tabs,
  }
}

export const formatClosingBinderToAPI = ({
  binderType: binder_type,
  clientName: client_name,
  closingDates,
  content,
  crossReferences: cross_references,
  nickname,
  partyNames: party_names,
  pdfBookmarks: pdf_bookmarks,
  slipPage: slip_page,
  templateId: template,
  title: name,
  transactionId: transaction,
  customCoverPageId: custom_cover_page,
  workingGroupDocumentId: working_group_page,
  formatStructure,
  hasSlipPages: has_slip_pages,
  hasAttachmentBookmarks: has_attachment_bookmarks,
  hasBackToIndexButtons: has_back_to_index_buttons,
  hasAttachmentCrossReferences: has_attachment_cross_references,
}: ClosingBinder): APIClosingBinder => ({
  name,
  nickname,
  client_name,
  cross_references,
  pdf_bookmarks,
  party_names,
  closing_dates: closingDates
    ? {
        category:
          formatClosingDateCategoryToAPI(closingDates.category) || "closing",
        closing_date: closingDates.closingDate || "",
        signing_date: closingDates.signingDate || "",
      }
    : undefined,
  content,
  custom_cover_page,
  binder_type,
  slip_page,
  template,
  transaction,
  format_structure: formatClosingBinderStructureToAPI(formatStructure),
  working_group_page,
  has_slip_pages,
  has_attachment_bookmarks,
  has_back_to_index_buttons,
  has_attachment_cross_references,
})

export const formatClosingBinderContentToAPI = (contentInput = []) =>
  contentInput.map(
    ({ id, marker, name, content, category, attachments_for_index }) => {
      if (category === "header") {
        return {
          id,
          category,
          marker,
          name,
          content: formatClosingBinderContentToAPI(content),
        }
      }
      return {
        pk: id,
        marker,
        category,
        attachments_for_index,
      }
    }
  )

export const formatClosingBinderTemplateFromAPI = ({
  uuid: id,
  external_name: name,
  binder_type: type,
  default_for_organization: isDefault,
}) => ({
  id,
  name,
  type,
  isDefault,
})
