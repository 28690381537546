import * as React from "react"

type IndexHeaderBtnProps = {
  isDisabled?: boolean
  label?: React.ReactNode
  icon?: React.ReactNode
  onClick?: () => void
}

export default function IndexHeaderBtn({
  isDisabled = false,
  label,
  icon,
  onClick = () => null,
}: IndexHeaderBtnProps) {
  return (
    <button
      className="flex flex-shrink-0 flex-col items-center rounded p-2 pb-1 hover:bg-gray-300 hover:bg-opacity-50 disabled:cursor-not-allowed disabled:opacity-60"
      onClick={onClick}
      disabled={isDisabled}
    >
      <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gradient-to-b from-blue-6 to-blue-9 text-white shadow-btn hover:from-blue-9 hover:to-blue-9">
        {icon}
      </div>
      <div className="pt-1 text-xxs text-blue-6">{label}</div>
    </button>
  )
}
