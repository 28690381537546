import { useRef } from "react"

import { useDrop } from "react-dnd"

import { BinderItemCard } from "."

type IndexItemCardProps = {
  index: number
  label?: number
  setHoverIndex?: (index: number) => void
  onCancel?: () => void
  onDrop?: () => void
  transactionId: string
  id: string
}

export default function IndexItemCard({
  index,
  id,
  onDrop = () => null,
  setHoverIndex = () => null,
  onCancel = () => null,
  transactionId,
  ...props
}: IndexItemCardProps) {
  const cardRef = useRef(null)

  function handleHover(item, monitor) {
    if (!cardRef.current) {
      return
    }
    const dragIndex = item.index
    const hoverIndex = index
    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return
    }
    // Determine rectangle on screen
    const hoverBoundingRect = cardRef.current?.getBoundingClientRect()
    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
    // Determine mouse position
    const clientOffset = monitor.getClientOffset()
    // Get pixels to the top
    const hoverClientY = clientOffset.y - hoverBoundingRect.top
    // Only perform the move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%
    // Dragging downwards
    if (hoverClientY > hoverMiddleY) {
      setHoverIndex(index + 1)
    } else {
      setHoverIndex(index)
    }
  }

  const [, dropRef] = useDrop({
    accept: ["ITEM", "HEADER"],
    hover: handleHover,
    drop: onDrop,
  })

  dropRef(cardRef)

  return (
    <div ref={cardRef} className="py-1">
      <BinderItemCard
        {...props}
        id={id}
        inBinder
        transactionId={transactionId}
        onCancel={onCancel}
      />
    </div>
  )
}
