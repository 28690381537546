export type ClosingBinderTemplate = {
  id: string
  name: string
  type: string
  isDefault: boolean
}

export type APIClosingBinderTemplate = {
  uuid: string
  external_name: string
  binder_type: string
  default_for_organization: boolean
}

export const decode = ({
  uuid: id,
  external_name: name,
  binder_type: type,
  default_for_organization: isDefault,
}: APIClosingBinderTemplate): ClosingBinderTemplate => ({
  id,
  name,
  type,
  isDefault,
})
