import styled from "@emotion/styled"
import { Colors, Button, Icon } from "@blueprintjs/core"
import { useDrop, useDrag } from "react-dnd"
import { getEmptyImage } from "react-dnd-html5-backend"
import { useEffect, useState } from "react"

import { useIsExpanded } from "features/closing-binders/expanded-headers"

import { EditableText } from "components/text"

import { InsertBar } from "./BinderBlock"
import IndexItemCard from "./IndexItemCard"
import IndexHeaderCard from "./IndexHeaderCard"
import isDroppablePathValid from "./is-droppable-path-valid"

const HeaderCard = styled.div`
  padding: 0rem 0.75rem 0rem 0.75rem;
  background-color: white;
  border-radius: 3px;
  border: 1px solid transparent;
  ${(props) =>
    props.isOver &&
    `
    border: 1px solid ${Colors.VIOLET4};
    /* Colors.VIOLET4 at 70% transparency */
    background-color: rgba(189, 107, 189, 0.3);
`}
`

const Name = styled.div``

const TitleRow = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  height: 42px;
  .edit,
  .delete-trashcan {
    display: none;
  }
  &:hover {
    .edit,
    .delete-trashcan {
      display: inline-block;
    }
  }
`

const HeaderChildContent = styled.div`
  counter-reset: closing-binder-counter;
`

type BinderHeaderCardProps = {
  content?: any[]
  id?: string
  isOverParent?: boolean
  isPreview?: boolean
  marker?: string
  name?: string
  path: any[]
  onCancel?: () => void
  onDelete?: () => void
  onEdit?: () => void
  onInsertAtPath?: () => void
  onIsOverHeader?: () => void
}

export default function BinderHeaderCard(props: BinderHeaderCardProps) {
  const {
    id,
    marker,
    name,
    path = [],
    content = [],
    onDelete = () => null,
    onEdit = () => null,
    onIsOverHeader = () => null,
    onInsertAtPath = () => null,
    onCancel = () => null,
    isPreview,
  } = props

  const [isEditing, setIsEditing] = useState(false)
  const [dropIndex, setDropIndex] = useState(null)
  const [canDrop, setCanDrop] = useState(true)
  const [isHovering, setIsHovering] = useState(true)
  const [isExpanded, setIsExpanded] = useIsExpanded(id)

  const [{ isDragging }, dragRef, preview] = useDrag({
    item: { type: "HEADER", id, name, path, content, marker },
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
    end: (item, monitor) => {
      if (!monitor.didDrop()) {
        onCancel()
      }
    },
  })
  const [{ isOver }, dropRef] = useDrop({
    accept: ["ITEM", "HEADER"],
    collect: (monitor) => ({ isOver: monitor.isOver() }),
    drop: handleDrop,
    hover: handleHover,
    canDrop: (droppable) => canDrop && isDroppablePathValid(droppable, path),
  })

  useEffect(() => {
    preview(getEmptyImage())
  }, [])

  useEffect(() => {
    if (isPreview) {
      setIsExpanded(false)
    }
  }, [isPreview])

  function handleDrop(item) {
    if (item.id === id) {
      return
    }

    onInsertAtPath(
      [...path, dropIndex === null ? content.length : dropIndex],
      item
    )
    setIsExpanded(true)
  }

  function handleIsOverChildHeader(isOverHeader) {
    if (isOverHeader) {
      setDropIndex(null)
    }
    setCanDrop(!isOverHeader)
  }

  function handleHover() {
    if (!isHovering) {
      setIsHovering(true)
    }
  }

  useEffect(() => {
    if (!isOver) {
      setIsHovering(false)
    }
  }, [isOver])

  useEffect(() => {
    onIsOverHeader(isOver)
  }, [isOver])

  function onNameEdit(newName) {
    onEdit({ type: "HEADER", id, name: newName, path, content })
  }

  function handleSetHoverIndex(index) {
    setDropIndex(index)
  }

  function handleCancel() {
    setDropIndex(null)
  }

  const isShowingHoverUI = isOver && canDrop

  return (
    <div
      ref={(node) => dragRef(dropRef(node))}
      style={{
        opacity: isDragging ? 0.5 : 1,
        width: isPreview ? "360px" : "100%",
      }}
    >
      <HeaderCard
        className={isOver ? "" : "bp4-elevation-0"}
        isOver={isShowingHoverUI}
      >
        <TitleRow>
          <div className="mr-1">{marker}.</div>
          {isEditing ? (
            <EditableText
              isEditing
              text={name}
              onSave={onNameEdit}
              onCancel={() => setIsEditing(false)}
              id={id}
            />
          ) : (
            <Name className="flex min-w-0 items-center">
              <div className="truncate">{name}</div>
              <Button
                className="ml-1"
                small
                minimal
                icon={<Icon className="edit" icon="edit" size={12} />}
                onClick={(evt) => {
                  evt.stopPropagation()
                  setIsEditing(true)
                }}
              />
            </Name>
          )}
          <Button
            small
            minimal
            className="ml-1"
            icon={<Icon className="delete-trashcan" size="12" icon="trash" />}
            onClick={() => onDelete(id, path)}
          />
          {content.length > 0 && (
            <Button
              className="ml-auto"
              minimal
              onClick={() => setIsExpanded(!isExpanded)}
              icon={isExpanded ? "chevron-up" : "chevron-down"}
            />
          )}
        </TitleRow>
        {content.length > 0 && isExpanded && (
          <HeaderChildContent>
            {content.map((node, index) => {
              const Component =
                node.type === "HEADER" ? IndexHeaderCard : IndexItemCard
              return (
                <div className="relative" key={node.id}>
                  {false && <InsertBar />}
                  {isShowingHoverUI && index === dropIndex && (
                    <InsertBar depth={path.length} />
                  )}
                  <Component
                    {...props}
                    {...node}
                    setHoverIndex={handleSetHoverIndex}
                    onCancel={handleCancel}
                    index={index}
                    onIsOverHeader={handleIsOverChildHeader}
                  />
                </div>
              )
            })}
            <div className="relative py-1">
              {isShowingHoverUI &&
                content.length > 0 &&
                (dropIndex === content.length || dropIndex === null) && (
                  <InsertBar depth={path.length} />
                )}
            </div>
          </HeaderChildContent>
        )}
      </HeaderCard>
    </div>
  )
}
