import * as React from "react"

import Link from "next/link"
import { Colors, Icon, Menu, MenuDivider } from "@blueprintjs/core"
import { Tooltip2, MenuItem2 as MenuItem } from "@blueprintjs/popover2"

import { toggleItem } from "helpers/array"
import { useTransactionIdParam } from "helpers/params"

import { useUpdateBinderContentNode } from "features/closing-binders/api"

import sortByAttachment from "./sort-by-attachment"

type BinderAttachmentToggleMenuProps = {
  attachments?: any[]
  attachmentIdsForIndex?: any[]
  binderId?: string
  itemId: string
  isLocked?: boolean
}

export default function BinderAttachmentToggleMenu({
  attachmentIdsForIndex = [],
  attachments = [],
  binderId,
  itemId,
  isLocked = false,
}: BinderAttachmentToggleMenuProps) {
  let transactionId = useTransactionIdParam()
  let { updateNode } = useUpdateBinderContentNode()

  let hasAttachments = attachments.length > 0

  let attachmentsWithIsSelected = attachments.map((attachment) => ({
    ...attachment,
    isSelected: attachmentIdsForIndex.includes(attachment.id),
  }))

  function handleToggle(id) {
    if (binderId) {
      updateNode(binderId, itemId, {
        attachmentIdsForIndex: sortByAttachment(
          attachments,
          toggleItem(attachmentIdsForIndex, id)
        ),
      })
    }
  }

  return (
    <Menu>
      {hasAttachments && (
        <MenuDivider
          title={
            <div className="flex items-center">
              Attachments
              {!isLocked && (
                <Tooltip2
                  className="ml-1"
                  openOnTargetFocus={false}
                  content="Starred attachments will be displayed as subitems in the index"
                >
                  <Icon size={14} icon="info-sign" />
                </Tooltip2>
              )}
            </div>
          }
        />
      )}
      {attachmentsWithIsSelected.map((page) => (
        <MenuItem
          onClick={(evt) => {
            evt.stopPropagation()
            evt.preventDefault()
            if (!isLocked) {
              handleToggle(page.id)
            }
          }}
          key={page.id}
          text={
            <div className="flex items-center">
              {`${page.label}: ${page.name}`}
              {!isLocked && (
                <Icon
                  className="ml-auto pl-2"
                  icon="star"
                  color={page.isSelected ? Colors.GOLD5 : Colors.GRAY4}
                />
              )}
            </div>
          }
        />
      ))}
      {!isLocked && (
        <>
          {hasAttachments && <MenuDivider />}
          <Link href={`/transaction/${transactionId}/item/${itemId}`} passHref>
            <MenuItem text="Add attachments..." />
          </Link>
        </>
      )}
    </Menu>
  )
}
