import { useDragLayer } from "react-dnd"
import { BinderItemCard } from "components/closing-binder"
import BinderHeaderCard from "components/closing-binder/BinderHeaderCard"
// Position dragging ESignTab 20px left of mouse always
export const X_POINTER_NODE_DRAGGING_OFFSET = 100

type DraggableItemCardProps = {
  numDraggingDocuments?: number
}

export default function DraggableItemCard({
  numDraggingDocuments,
}: DraggableItemCardProps) {
  const {
    itemType,
    isDragging,
    item = {},
    initialPointerOffset,
    initialOptionNodeOffset,
    currentOffset,
  } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialPointerOffset: monitor.getInitialClientOffset(),
    initialOptionNodeOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }))

  if (!isDragging || !initialOptionNodeOffset) return null

  const isItemAlreadyOnPage = !!item.id

  const xDeltaFromPointer =
    initialPointerOffset.x -
    initialOptionNodeOffset.x -
    X_POINTER_NODE_DRAGGING_OFFSET

  const { x, y } = currentOffset

  const transform = `translate(${
    x + (isItemAlreadyOnPage ? 0 : xDeltaFromPointer)
  }px, ${y}px)`

  const Component = itemType === "ITEM" ? BinderItemCard : BinderHeaderCard

  return (
    <div
      style={{
        position: "fixed",
        pointerEvents: "none",
        zIndex: 100,
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
      }}
    >
      <div style={{ transform }}>
        {numDraggingDocuments > 1 ? (
          <Component
            isPreview={isDragging}
            name={`Move ${numDraggingDocuments} documents here`}
          />
        ) : (
          <Component isPreview={isDragging} {...item} />
        )}
      </div>
    </div>
  )
}
