import { useRef } from "react"

import { useDrop } from "react-dnd"

import BinderHeaderCard from "./BinderHeaderCard"

type IndexHeaderCardProps = {
  index: number
  label?: number
  setHoverIndex?: () => void
  onCancel?: () => void
  onDrop?: () => void
}

export default function IndexHeaderCard({
  index,
  onDrop = () => null,
  setHoverIndex = () => null,
  onCancel = () => null,
  ...props
}: IndexHeaderCardProps) {
  const cardRef = useRef(null)

  function handleHover(item, monitor) {
    if (!cardRef.current) return

    // For Header cards only show the over UI if pointer is
    // not over child card as header child card accepts a drop
    if (monitor.isOver({ shallow: true })) {
      const dragIndex = item.index
      const hoverIndex = index
      if (dragIndex === hoverIndex) {
        return
      }
      // Keeping vertical middle calculation because it makes it
      // easy to determine where to put hover UI
      const hoverBoundingRect = cardRef.current?.getBoundingClientRect()
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      if (hoverClientY > hoverMiddleY) {
        setHoverIndex(index + 1)
      } else {
        setHoverIndex(index)
      }
    }
  }

  const [, dropRef] = useDrop({
    accept: ["ITEM", "HEADER"],
    hover: handleHover,
    drop: onDrop,
  })

  dropRef(cardRef)

  return (
    <div ref={cardRef} className="py-1">
      <BinderHeaderCard {...props} inBinder index={index} onCancel={onCancel} />
    </div>
  )
}
