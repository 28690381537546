import * as React from "react"

import { Menu, MenuDivider, Position } from "@blueprintjs/core"
import {
  Popover2,
  Tooltip2,
  MenuItem2 as MenuItem,
} from "@blueprintjs/popover2"

import EditIndexFormatIcon from "icons/edit-index-format.svg"
import PreviewIcon from "icons/preview-icon.svg"
import OptionsIcon from "icons/options.svg"

import BinderIndexFormatModal from "features/closing-binders/BinderIndexFormatModal"
import BinderIndexPreviewModal from "features/closing-binders/BinderIndexPreviewModal"
import IndexHeaderBtn from "features/closing-binders/IndexHeaderBtn"

import MenuItemToggle from "./MenuItemToggle"

type BinderConfigMenuProps = {
  binder: Record<string, any>
}

export default function BinderConfigMenu({ binder }: BinderConfigMenuProps) {
  let [currentModal, setCurrentModal] = React.useState(null)

  let toggleOnForm = () => setCurrentModal("INDEX_FORM")
  let toggleOnPreview = () => setCurrentModal("PREVIEW")
  let toggleOff = () => setCurrentModal(null)

  return (
    <>
      <Popover2
        interactionKind="click"
        position={Position.BOTTOM_RIGHT}
        content={
          <Menu>
            <MenuItem
              className="flex items-center"
              icon={
                <EditIndexFormatIcon className="h-4 w-4" viewBox="0 0 24 18" />
              }
              text="Edit index format"
              onClick={toggleOnForm}
            />
            <MenuItem
              className="flex items-center"
              icon={
                <PreviewIcon
                  className="h-4 w-4 text-gray-550"
                  viewBox="0 0 21 26"
                />
              }
              text="Preview cover page and index"
              onClick={toggleOnPreview}
            />
            <MenuDivider />
            <MenuItemToggle
              binderId={binder.id}
              label="Add slip page before each document"
              field="hasSlipPages"
              isChecked={binder.hasSlipPages}
              toolTipContent="Enable this option to add a cover page before each document with the document's name"
            />
            <MenuItemToggle
              binderId={binder.id}
              label="Add PDF bookmarks for attachments"
              field="hasAttachmentBookmarks"
              isChecked={binder.hasAttachmentBookmarks}
              toolTipContent="Enable this option to insert PDF bookmarks for all document attachments"
            />
            <MenuItemToggle
              binderId={binder.id}
              label="Add index navigation button"
              field="hasBackToIndexButtons"
              isChecked={binder.hasBackToIndexButtons}
              toolTipContent="Enable this option to have a “Back to index” button on every page of your binder"
            />
          </Menu>
        }
      >
        <Tooltip2 content="Customize the binder">
          <IndexHeaderBtn
            label="Options"
            icon={<OptionsIcon viewBox="-1 2 23 22" />}
          />
        </Tooltip2>
      </Popover2>
      {currentModal === "PREVIEW" && (
        <BinderIndexPreviewModal
          binderId={binder.id}
          workingGroupDocumentId={binder.workingGroupDocumentId}
          onClose={toggleOff}
        />
      )}
      {currentModal === "INDEX_FORM" && (
        <BinderIndexFormatModal binder={binder} onClose={toggleOff} />
      )}
    </>
  )
}
