import PDFJS from "lib/pdf"
import {
  DocumentInitParameters,
  TypedArray,
} from "pdfjs-dist/types/src/display/api"

type DataType =
  | string
  | URL
  | TypedArray
  | PDFJS.PDFDataRangeTransport
  | DocumentInitParameters

class PDFDocument {
  documentData: DataType
  pdfLib: typeof PDFJS
  libDocument: any // TODO-TS: can't figure this one out

  constructor(data: DataType, lib: typeof PDFJS = PDFJS) {
    this.documentData = data
    this.pdfLib = lib
  }

  async getNumPages() {
    if (!this.libDocument) {
      this.libDocument = await this.pdfLib.getDocument(this.documentData)
        .promise
    }

    return this.libDocument.numPages
  }
}

export default PDFDocument
