import * as React from "react"

import { HTMLSelect } from "@blueprintjs/core"

import { MARKER_LIST_TYPES } from "features/closing-binders/marker-list-types"

const HEADER_OPTIONS = [
  { value: MARKER_LIST_TYPES.UPPERCASE_ROMAN, label: "I., II., III..." },
  { value: MARKER_LIST_TYPES.UPPERCASE_ALPHABETIC, label: "A., B., C..." },
  { value: MARKER_LIST_TYPES.LOWERCASE_ROMAN, label: "i., ii., iii..." },
  { value: MARKER_LIST_TYPES.LOWERCASE_ALPHABETIC, label: "a., b., c..." },
  { value: MARKER_LIST_TYPES.ARABIC, label: "1., 2., 3..." },
]

export type HeaderOptionsType = Record<keyof typeof MARKER_LIST_TYPES, string>

type HeaderSelectProps = {
  name?: string
  value?: string
  onChange?: (val: string) => void
}

export default function HeaderSelect({
  name,
  value: valueInput,
  onChange = () => null,
}: HeaderSelectProps) {
  return (
    <HTMLSelect
      className="w-full"
      name={name}
      value={valueInput}
      onChange={(evt) => onChange(evt.currentTarget.value)}
    >
      {HEADER_OPTIONS.map(({ value, label }) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </HTMLSelect>
  )
}
