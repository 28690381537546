import { useEffect } from "react"
import cx from "classnames"
import { Button, Icon, Intent, ProgressBar } from "@blueprintjs/core"
import { motion, AnimatePresence } from "framer-motion"
import { Tooltip2 } from "@blueprintjs/popover2"

import { useOnUnMountEffect, useHasEverChangedFromTo } from "helpers/hooks"
import { useBuildWatchStatus } from "features/closing-binders/build-watch"
import { useCancelBuildBinder } from "features/closing-binders/api"
import { useNotifications } from "features/NotificationsProvider"

const STATUS_TITLES: Record<string, string> = {
  COMPLETE: "Success!",
  ERROR: "Something went wrong",
}

const STATUS_SUBTITLES: Record<string, string> = {
  COMPLETE:
    "Click on the notifications menu to download or share the closing binder.",
  ERROR:
    "Please try again. If the problem persists, our support team can help identify the problematic document!",
}

const STAGE_SUBTITLES: Record<string, string> = {
  links: "Adding hyperlinks and PDF bookmarks",
  portfolio: "Building PDF Portfolio binder",
  optimizing: "Optimizing the PDF file size",
  zip_file: "Adding hyperlinks and PDF bookmarks",
}

function titleForStatus(status: string) {
  return STATUS_TITLES[status] || "We are building the closing binder."
}

function subTitleForStage(currentStage: string) {
  return STAGE_SUBTITLES[currentStage] || ""
}

function subTitleForStatus(status: string) {
  return STATUS_SUBTITLES[status] || "Generating the cover page and index"
}

const NullData = {}

type BuildStatusCardProps = {
  binderId?: string
}

export default function BuildStatusCard({ binderId }: BuildStatusCardProps) {
  let { status, data, clear, checkForBuild } = useBuildWatchStatus()
  const { cancelBuildBinder, isLoading: isCanceling } = useCancelBuildBinder()
  let { isTruthy: didComplete, reset } = useHasEverChangedFromTo(
    "WATCHING",
    "COMPLETE",
    status
  )
  let { refetchNotifications } = useNotifications()
  useOnUnMountEffect(clear)

  useEffect(() => {
    checkForBuild(binderId)
  }, [binderId])

  useEffect(() => {
    if (status !== "IDLE") {
      clear()
    }
  }, [])

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>
    let fn = () => {
      refetchNotifications()
      reset()
      clear()
    }

    if (didComplete) {
      timer = setTimeout(fn, 7000)
    }

    return () => clearTimeout(timer)
  }, [didComplete])

  let { currentStep, currentStage, totalSteps, message } = data || NullData

  let classes = cx(
    "bg-white py-2 px-2 rounded relative border border-gray-300",
    "overflow-hidden",
    {
      "border-red-500": status === "ERROR" || isCanceling,
      "border-green-sa-1": status === "COMPLETE" && !isCanceling,
      "pb-4": status === "WATCHING",
    }
  )

  const handleCancelBinderBuild = () => {
    clear()
    reset()
    cancelBuildBinder(String(binderId))
  }

  return (
    <AnimatePresence>
      {!["IDLE", "LOADING"].includes(status) && (
        <motion.div
          className={classes}
          initial={{ opacity: 0, y: -20, height: 0 }}
          animate={{ opacity: 1, y: 0, height: "auto" }}
          transition={{ duration: 0.2 }}
          exit={{ opacity: 0, y: -54, height: 0 }}
        >
          <div className="flex items-center">
            {status === "ERROR" && (
              <Icon className="mr-1" icon="info-sign" intent={Intent.DANGER} />
            )}
            {status === "COMPLETE" && !isCanceling && (
              <Icon
                className="mr-1"
                icon="tick-circle"
                intent={Intent.SUCCESS}
              />
            )}
            <div className="leading-4 tracking-wider">
              {titleForStatus(status)}
            </div>
          </div>
          <div className="pt-2 text-xs font-light italic leading-3 text-gray-550">
            {currentStage === "merging_items" ? (
              <span>Compiling documents: {message}</span>
            ) : (
              <>
                {message ||
                  subTitleForStage(currentStage) ||
                  subTitleForStatus(status)}
              </>
            )}
          </div>
          {status === "WATCHING" && (
            <div className="absolute bottom-0 left-0 z-10 w-full">
              <ProgressBar
                className="cb-build-progress-bar"
                value={currentStep / totalSteps || 0.01}
                intent={Intent.PRIMARY}
              />
            </div>
          )}
          {status !== "WATCHING" && (
            <div className="absolute right-0 top-0 p-1">
              <Button small minimal icon="cross" onClick={() => clear()} />
            </div>
          )}
          {status !== "COMPLETE" && status !== "ERROR" && (
            <div className="absolute right-0 top-0 p-1">
              <Tooltip2 content="Cancel binder">
                <Button
                  small
                  minimal
                  icon="delete"
                  onClick={() => handleCancelBinderBuild()}
                />
              </Tooltip2>
            </div>
          )}
        </motion.div>
      )}
    </AnimatePresence>
  )
}
