import { LegacyRef, useEffect, useState, FocusEvent } from "react"
import { useForm } from "react-hook-form"
import {
  Button,
  FormGroup,
  Icon,
  InputGroup,
  Intent,
  Menu,
  MenuDivider,
  PopoverInteractionKind,
  Position,
  Switch,
  Tag,
  Text,
} from "@blueprintjs/core"
import {
  Popover2,
  Tooltip2,
  MenuItem2 as MenuItem,
} from "@blueprintjs/popover2"
import styled from "@emotion/styled"

import { useDrag } from "react-dnd"
import { getEmptyImage } from "react-dnd-html5-backend"

import AddToHeaderIcon from "icons/add-to-header.svg"
import PaperClipIcon from "icons/paperclip.svg"
import ManualCheckMark from "icons/manual-check-mark.svg"

import { useUpdateItem } from "features/transaction/id/api"

import BinderAttachmentToggleMenu from "features/closing-binders/BinderAttachmentToggleMenu"

import { EditableText } from "components/text"
import AddDocumentBtn from "features/transaction/id/DashboardMain/AddElement/AddDocumentBtn"
import VersionManager from "features/item-detail/VersionManager"
import { Page } from "models/Pages"
import { useAppConfig } from "app/config"

const Container = styled.div<{
  isSelected?: boolean
  isDragging?: boolean
  ghosting?: boolean
  isPlaceholder?: boolean
  inBinder?: boolean
}>`
  padding: ${(props) =>
    props.isPlaceholder && !props.inBinder && !props.isSelected
      ? "2px 0 2px 12px"
      : "10px 9px 10px 12px"};
  display: flex;
  justify-content: space-between;
  align-items: center;
  opacity: ${(props) => (props.ghosting ? "30%" : "100%")};
  background-color: #ffffff;
  border-radius: 3px;
  position: relative;

  ${(props) =>
    props.isSelected
      ? `border: ${props.isPlaceholder ? "2.5px dotted" : "1px solid"} #a854a8;`
      : `border: ${
          props.isPlaceholder ? "2.5px dotted" : "1px solid"
        } #dbdcdd;`}
  ${(props) => props.isDragging && `width: 360px;`}

  .edit {
    display: none;
  }

  &:hover {
    cursor: pointer;
    .edit {
      display: inline-block;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    margin-left: 7px;

    & > :not(:last-child) {
      margin-right: 7px;
    }
  }

  .gray-circle {
    border-radius: 100%;
    color: #5c7080;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    cursor: pointer;
  }

  .green-circle {
    background: #43bf4d;
    border-radius: 100%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    cursor: pointer;
  }

  .attachment-menu-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .insert-documents {
    position: relative;
    top: -0.8px;
    right: -13px;
    height: 42px;
    width: 42px;

    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(16, 22, 26, 0.4);
    border-radius: 0 3px 3px 0;
  }
`

type BinderItemCardProps = {
  attachmentIdsForIndex: string[]
  binderId?: string
  id: string
  path?: number[]
  marker?: number
  name?: string
  nameAlt?: string
  nameAltEnabled?: boolean
  description?: string
  onNameChange?: () => void
  onSendTo?: (header: { id: string; name: string; marker: string }) => void
  pages?: Page[]
  frozenAt?: string
  forSigning?: boolean
  fullyExecuted?: boolean
  isDeed?: boolean
  isSelected?: boolean
  inBinder?: boolean
  ghosting?: boolean
  isPreview?: boolean
  canDrag?: boolean
  headers?: {
    id: string
    marker: string
    name: string
  }[]
  selectionCount?: number
  onToggleSelection?: (id: string) => void
  onCancel: () => void
  transactionId: string
}

export const BinderItemCard = ({
  attachmentIdsForIndex: attachmentIdsForIndexInput,
  marker,
  path,
  id,
  name,
  nameAlt,
  nameAltEnabled,
  description,
  onSendTo = () => null,
  pages = [],
  frozenAt,
  forSigning: executable = true,
  fullyExecuted: executed = false,
  isSelected = false,
  isDeed = false,
  inBinder = false,
  ghosting = false,
  isPreview = false,
  headers = [],
  selectionCount = 0,
  transactionId,
  onToggleSelection = () => null,
  canDrag = true,
  onCancel,
  binderId,
}: BinderItemCardProps) => {
  let { config } = useAppConfig()
  let isAltDocNamesEnabled = config?.FLAGS?.ALTERNATE_DOCUMENT_NAMES
  const { updateItem } = useUpdateItem(transactionId)
  const [localName, setLocalName] = useState(name)
  let spacing = " "
  if (description && ",;:".includes(description[0] ?? "")) {
    spacing = ""
  }
  const indexName = nameAltEnabled
    ? (nameAlt + spacing + description).trim()
    : ""
  const [isEditingName, setIsEditingName] = useState(false)
  const [isEditingDetails, setIsEditingDetails] = useState(false)
  const [isAddingDocument, setIsAddingDocument] = useState(false)
  const [{ isDragging }, dragRef, preview] = useDrag({
    item: {
      type: "ITEM",
      id,
      name: localName,
      inBinder,
      marker,
      path,
      pages,
      forSigning: executable,
      fullyExecuted: executed,
    },
    canDrag: () => canDrag,
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
    end: (item, monitor) => {
      if (!monitor.didDrop()) {
        onCancel()
      }
    },
  })
  const defaultFormValues = {
    nameAlt: nameAlt === "" ? name : nameAlt,
    nameAltEnabled: nameAltEnabled,
    description: description,
  }
  const {
    register,
    resetField,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    setFocus,
    setValue,
    watch,
  } = useForm<{
    nameAlt: string
    nameAltEnabled: boolean
    description: string
  }>({
    defaultValues: defaultFormValues,
    mode: "onChange",
  })

  const { ref: nameAltEnabledRef, ...nameAltEnabledProps } = register(
    "nameAltEnabled",
    {
      onChange: (e: FocusEvent<HTMLInputElement>) => {
        const validDisabledForm =
          !e.target.checked &&
          !errors.description &&
          errors.nameAlt?.message === nameAltRequiredMessage
        const validForm = isValid || validDisabledForm
        if (e.target.form && validForm) {
          e.target.form.requestSubmit()
        } else {
          resetField("nameAltEnabled", { defaultValue: !e.target.checked })
        }
      },
    }
  )
  const nameAltRequiredMessage = "External Name is required"
  const { ref: nameAltRef, ...nameAltProps } = register("nameAlt", {
    maxLength: {
      value: 350,
      message: "External Name must be less than 350 characters",
    },
    validate: (value, formValues) => {
      if (formValues.nameAltEnabled && !value) {
        return nameAltRequiredMessage
      }
      return true
    },
    onBlur: (e: FocusEvent<HTMLInputElement>) => {
      if (e.target.form && isValid) {
        e.target.form.requestSubmit()
      }
    },
  })

  const { ref: descriptionRef, ...descriptionProps } = register("description", {
    maxLength: {
      value: 350,
      message: "Description must be less than 350 characters",
    },
    onBlur: (e: FocusEvent<HTMLInputElement>) => {
      if (e.target.form && isValid) {
        e.target.form.requestSubmit()
      }
    },
  })

  useEffect(() => {
    preview(getEmptyImage())
  }, [])

  useEffect(() => {
    setLocalName(name)
  }, [name])

  const isNameAltEnabled = watch("nameAltEnabled")
  useEffect(() => {
    if (isNameAltEnabled) {
      setFocus("nameAlt")
    }
  }, [isNameAltEnabled])

  function handleUpdateName(value: string) {
    setLocalName(value)
    updateItem({
      id,
      name: value,
    })
    if (!nameAlt) {
      setValue("nameAlt", value)
    }
  }

  function handleDetailsUpdate(data: {
    nameAltEnabled: boolean
    nameAlt: string
    description: string
  }) {
    updateItem({
      id,
      nameAltEnabled: data.nameAltEnabled,
      nameAlt: data.nameAlt,
      description: data.description,
    })
    if (data.nameAlt === "") {
      resetField("nameAlt", { defaultValue: name })
    }
  }

  const getIntent = (field: keyof typeof errors) =>
    errors[field] ? Intent.DANGER : Intent.NONE

  const isDeedAndIsFrozen = isDeed && Boolean(frozenAt)
  const attachmentPages = pages.filter((p) => p.type === "attachment")
  const hasAttachments = attachmentPages.length > 0
  const attachmentIdsForIndex = attachmentIdsForIndexInput?.filter((pageId) =>
    // Only include attachment ids that are still a page
    // in the item
    attachmentPages.find((page) => page.id === pageId)
  )

  if (isDragging && selectionCount) {
    return <Container>Move {selectionCount} documents here</Container>
  }

  function handleClick() {
    if (!isEditingName) {
      onToggleSelection(id)
    }
  }

  let isPlaceholder =
    pages.length === 0 || pages.every((p) => p.type === "instapagev2")

  return (
    <div
      ref={dragRef as unknown as LegacyRef<HTMLDivElement>}
      style={{ opacity: isDragging ? 0.5 : 1 }}
    >
      <Container
        isSelected={isSelected}
        onClick={handleClick}
        ghosting={ghosting}
        isDragging={isPreview}
        className={!isSelected ? "hover:shadow" : ""}
        isPlaceholder={isPlaceholder}
        inBinder={false}
      >
        <div className="flex min-w-0 flex-1">
          {inBinder && marker && (
            <div className="mr-1 self-baseline">{marker}.</div>
          )}
          <div className="flex-col self-baseline w-full">
            {isEditingName ? (
              <EditableText
                isEditing
                text={localName}
                onSave={handleUpdateName}
                onCancel={() => setIsEditingName(false)}
                id={id}
              />
            ) : (
              <div className="flex min-w-0 flex-1 items-center">
                <Text ellipsize>{localName}</Text>
                {isDeed && (
                  <Tag
                    className="ml-1 flex-shrink-0"
                    round
                    minimal
                    intent={Intent.PRIMARY}
                  >
                    Deed
                  </Tag>
                )}
                <Button
                  className="ml-1"
                  small
                  minimal
                  icon={<Icon className="edit" icon="edit" size={12} />}
                  onClick={(evt) => {
                    evt.stopPropagation()
                    setIsEditingName(true)
                  }}
                />
                {isAltDocNamesEnabled && (
                  <Popover2
                    minimal={true}
                    isOpen={isEditingDetails}
                    onClose={() => {
                      if (isValid) {
                        setIsEditingDetails(false)
                      } else {
                        setIsEditingDetails(false)
                        reset(defaultFormValues)
                      }
                    }}
                    position={Position.BOTTOM}
                    className="flex-shrink-0"
                    lazy={false}
                    content={
                      <div className="w-112 p-3">
                        <form
                          autoComplete="off"
                          onSubmit={handleSubmit((data) => {
                            handleDetailsUpdate(data)
                          })}
                        >
                          <FormGroup
                            className="!m-0"
                            intent={getIntent("nameAltEnabled")}
                          >
                            <Switch
                              label="Use a different name for document in binder index"
                              inline={true}
                              className="!m0"
                              alignIndicator="left"
                              inputRef={nameAltEnabledRef}
                              {...nameAltEnabledProps}
                            />
                          </FormGroup>
                          <div>
                            <FormGroup
                              label={
                                <div className="font-semibold">
                                  External Name
                                </div>
                              }
                              labelFor="nameAlt"
                              subLabel="The name that will appear in the binder index and as the file name for this document."
                              helperText={
                                errors.nameAlt ? (
                                  <span className="text-red-700">
                                    {errors.nameAlt.message}
                                  </span>
                                ) : (
                                  ""
                                )
                              }
                            >
                              <InputGroup
                                {...nameAltProps}
                                defaultValue={localName}
                                inputRef={nameAltRef}
                                intent={getIntent("nameAlt")}
                                disabled={!isNameAltEnabled}
                              />
                            </FormGroup>
                            <FormGroup
                              label={
                                <p className="font-semibold">Description</p>
                              }
                              subLabel="A description that will be added to the external name in the closing binder index."
                              labelFor="description"
                              helperText={
                                errors.description ? (
                                  <span className="text-red-700">
                                    {errors.description.message}
                                  </span>
                                ) : (
                                  ""
                                )
                              }
                            >
                              <InputGroup
                                {...descriptionProps}
                                inputRef={descriptionRef}
                                placeholder="ex: dated as of January 1, 2000, by and between Party A and Party B"
                                intent={getIntent("description")}
                                disabled={!isNameAltEnabled}
                              />
                            </FormGroup>
                          </div>
                          <input type="submit" hidden />
                        </form>
                      </div>
                    }
                  >
                    <Tooltip2
                      position="top"
                      content="Use a different document name in the binder index."
                    >
                      <Button
                        className="ml-1"
                        small
                        minimal
                        icon={<ManualCheckMark className="edit" size={12} />}
                        onClick={(evt) => {
                          evt.stopPropagation()
                          setIsEditingDetails(!isEditingDetails)
                          if (!isValid) {
                            reset(defaultFormValues)
                          }
                        }}
                      />
                    </Tooltip2>
                  </Popover2>
                )}
              </div>
            )}
            {isAltDocNamesEnabled && (
              <div className="line-clamp-2 text-gray-sa-4" title={indexName}>
                {indexName}
              </div>
            )}
          </div>
        </div>

        <div className="actions">
          {inBinder && (
            <div
              onClick={(event) => {
                event.stopPropagation()
              }}
              className="flex items-center"
            >
              {attachmentIdsForIndex?.length > 0 && (
                <Tooltip2
                  className="mr-[7px]"
                  content={`${attachmentIdsForIndex.length} attachment(s) displayed in index`}
                >
                  <div className="flex h-[21px] w-5 rounded-full bg-blue-6 text-white">
                    <span className="m-auto text-xs">
                      {attachmentIdsForIndex.length}
                    </span>
                  </div>
                </Tooltip2>
              )}
              <Popover2
                disabled={!hasAttachments && isDeedAndIsFrozen}
                position={Position.BOTTOM}
                content={
                  <BinderAttachmentToggleMenu
                    attachments={attachmentPages}
                    attachmentIdsForIndex={attachmentIdsForIndex}
                    binderId={binderId}
                    itemId={id}
                    isLocked={isDeedAndIsFrozen}
                  />
                }
              >
                <div
                  className={hasAttachments ? "green-circle" : "gray-circle"}
                >
                  <PaperClipIcon viewBox="0 0 12 26" height={14} />
                </div>
              </Popover2>
            </div>
          )}
          {!executable && !isPlaceholder && (
            <Tag minimal round>
              Non-executable
            </Tag>
          )}
          {executed && !isPlaceholder && (
            <Tag intent={Intent.PRIMARY} minimal round>
              Executed
            </Tag>
          )}
          {isSelected && !!headers?.length && (
            <>
              <div className="w-6" />
              <div
                className="absolute right-0 top-0 flex h-full w-10 rounded-r bg-gray-100"
                onClick={(event) => {
                  event.stopPropagation()
                }}
                style={{
                  borderLeft: "1px  solid #dbdcdd",
                }}
              >
                <Popover2
                  className="m-auto"
                  interactionKind={PopoverInteractionKind.CLICK}
                  position={Position.BOTTOM}
                  content={
                    <Menu>
                      <MenuDivider title={`Send document(s) to`} />

                      {headers.map((header) => (
                        <MenuItem
                          text={`${header.marker}. ${header.name}`}
                          key={header.id}
                          onClick={() => onSendTo(header)}
                        />
                      ))}
                    </Menu>
                  }
                >
                  <button className="color-gray-1 block">
                    <AddToHeaderIcon />
                  </button>
                </Popover2>
              </div>
            </>
          )}
          {isPlaceholder && !isSelected && (
            <AddDocumentBtn
              handleAddDocument={() => setIsAddingDocument(true)}
              tooltipPlacement="top-start"
            />
          )}
        </div>
      </Container>
      {isAddingDocument && (
        <VersionManager
          itemId={id}
          onClose={() => setIsAddingDocument(false)}
        />
      )}
    </div>
  )
}
