import { useState } from "react"

import { FieldValues, useForm } from "react-hook-form"
import {
  Button,
  FormGroup,
  InputGroup,
  Intent,
  Position,
} from "@blueprintjs/core"
import { Popover2, Tooltip2 } from "@blueprintjs/popover2"

import CreateHeaderIcon from "icons/create-header.svg"

import IndexHeaderBtn from "features/closing-binders/IndexHeaderBtn"

type AddHeaderProps = {
  onCreateHeader?: (data: FieldValues) => void
}

export default function AddHeader({
  onCreateHeader = () => null,
}: AddHeaderProps) {
  const [isFormOpen, setIsFormOpen] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm()

  const { ref: nameRef, ...nameRest } = register("name", { required: true })

  return (
    <Popover2
      isOpen={isFormOpen}
      onClose={() => {
        setIsFormOpen(false)
        reset()
      }}
      position={Position.BOTTOM}
      className="flex-shrink-0"
      lazy={false}
      content={
        <div className="w-56 p-3">
          <form
            onSubmit={handleSubmit((data) => {
              onCreateHeader(data)
              setIsFormOpen(false)
              reset()
            })}
          >
            <FormGroup label="Header Title">
              <InputGroup
                autoFocus
                intent={errors.name ? Intent.DANGER : undefined}
                inputRef={nameRef}
                placeholder="Enter a header title"
                {...nameRest}
              />
            </FormGroup>
            <div className="flex justify-end">
              <Button intent={Intent.PRIMARY} type="submit">
                Add
              </Button>
            </div>
          </form>
        </div>
      }
    >
      <Tooltip2 content="Create a new header">
        <IndexHeaderBtn
          label="Add header"
          icon={<CreateHeaderIcon height="20" width="20" viewBox="0 0 23 20" />}
          onClick={() => setIsFormOpen(true)}
        />
      </Tooltip2>
    </Popover2>
  )
}
