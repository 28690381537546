export type APIClosingDates = {
  category: "closing" | "sign_and_close" | "multiple"
  closing_date: string
  signing_date: string
}

export type ClosingDates = {
  category: "closing" | "signAndClose" | "multipleClosing"
  closingDate: string
  signingDate: string
}

const decodeCategory = (category: string): ClosingDates["category"] => {
  switch (category) {
    case "closing":
      return "closing"
    case "sign_and_close":
      return "signAndClose"
    case "multiple":
      return "multipleClosing"
    default:
      throw new Error(`Unknown category: ${category}`)
  }
}

export const decode = (json: APIClosingDates): ClosingDates => ({
  category: decodeCategory(json.category),
  closingDate: json.closing_date,
  signingDate: json.signing_date,
})
