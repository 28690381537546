import DocCounter from "features/closing-binders/doc-counter"
import PageCounter from "features/closing-binders/page-counter"
import * as React from "react"
import { PreviewNodeType } from ".."
import { MarkerGenerator } from "features/closing-binders/apply-markers"

const NullMarker = { get: () => null }

type PreviewNodeProps = {
  docCounter?: DocCounter | PageCounter
  node?: PreviewNodeType
  marker?: MarkerGenerator | typeof NullMarker
  level?: number
  idx?: number
  indexStyle?: "outline" | "table_of_contents"
  docLabel?: string
}

export default function PreviewNode({
  docCounter,
  indexStyle,
  node,
  level = 0,
  marker = NullMarker,
  docLabel,
}: PreviewNodeProps) {
  if (node) {
    return (
      <div className="">
        <div className="flex">
          {(node.type === "HEADER" || indexStyle === "outline") && (
            <div className="pr-1">{marker.get(level)}.</div>
          )}

          <div className="flex-shrink-0">
            {node.type === "ITEM" && docLabel && docLabel === "numbers" && (
              <>{docCounter?.get()}. </>
            )}
            {node.name}
          </div>
          {node.type === "ITEM" && docCounter && docLabel !== "numbers" && (
            <>
              <div className="relative flex-1 overflow-hidden">
                <div className="absolute whitespace-nowrap">
                  ....................................................
                </div>
              </div>
              <div className="flex-shrink-0">
                <div>{docCounter.get()}</div>
              </div>
            </>
          )}
        </div>
        {node.content && (
          <div className="pl-3">
            {node.content.map((child, idx) => (
              <PreviewNode
                docCounter={docCounter}
                key={child.name}
                node={child as PreviewNodeType}
                level={level + 1}
                idx={idx}
                marker={marker}
                indexStyle={indexStyle}
                docLabel={docLabel}
              />
            ))}
          </div>
        )}
      </div>
    )
  }

  return null
}
