import { MARKER_LIST_TYPES } from "./marker-list-types"
import DocCounter from "./doc-counter"
import { DocCounterOptionsClass } from "./BinderIndexFormatForm"
import { HeaderOptionsType } from "./BinderIndexFormatForm/HeaderSelect"

function toRomanNumeral(num: number) {
  if (isNaN(num)) return NaN
  var digits = String(+num).split(""),
    key = [
      "",
      "C",
      "CC",
      "CCC",
      "CD",
      "D",
      "DC",
      "DCC",
      "DCCC",
      "CM",
      "",
      "X",
      "XX",
      "XXX",
      "XL",
      "L",
      "LX",
      "LXX",
      "LXXX",
      "XC",
      "",
      "I",
      "II",
      "III",
      "IV",
      "V",
      "VI",
      "VII",
      "VIII",
      "IX",
    ],
    roman = "",
    i = 3
  while (i--) roman = (key[+digits.pop() + i * 10] || "") + roman
  return Array(+digits.join("") + 1).join("M") + roman
}

function toAlpha(num: number) {
  num = num - 1 // converting to zero-based numbering so A = 0, B = 1, etc.
  const letter = String.fromCharCode(97 + (num % 26))
  return letter.repeat(Math.floor(num / 26) + 1)
}

const NullDocCounter = { get() {}, reset() {} }

export default function applyMarkers(
  content: any,
  formats: {
    docNumbering?: keyof ReturnType<DocCounterOptionsClass["get"]> | "none"
    indexStyle?: "outline" | "table_of_contents"
    resetTabs?: boolean
    primaryHeader?: HeaderOptionsType
    secondHeader?: HeaderOptionsType
    thirdHeader?: HeaderOptionsType
    documentsHeader?: HeaderOptionsType
  } = {}
) {
  let docCounter =
    formats.docNumbering === "pages" ? NullDocCounter : new DocCounter()
  let isOutline = formats.indexStyle === "outline"

  function recursiveMarkers(content, depth = 0) {
    if (depth === 1 && formats.resetTabs) {
      docCounter.reset()
    }
    let marker = new MarkerGenerator([
      formats.primaryHeader || MARKER_LIST_TYPES.UPPERCASE_ROMAN,
      formats.secondHeader || MARKER_LIST_TYPES.UPPERCASE_ALPHABETIC,
      formats.thirdHeader || MARKER_LIST_TYPES.LOWERCASE_ROMAN,
      formats.documentsHeader || MARKER_LIST_TYPES.LOWERCASE_ALPHABETIC,
    ] as HeaderOptionsType[])

    return content?.map((node) => {
      if (node.type === "ITEM") {
        return {
          ...node,
          marker: isOutline ? marker.get(depth) : docCounter.get(),
        }
      }

      if (node.type === "HEADER") {
        return {
          ...node,
          marker: marker.get(depth),
          content: recursiveMarkers(node.content, depth + 1),
        }
      }
    })
  }

  return recursiveMarkers(content)
}

const listGenerators = {
  [MARKER_LIST_TYPES.UPPERCASE_ROMAN]: (num: number) =>
    String(toRomanNumeral(num)).toUpperCase(),
  [MARKER_LIST_TYPES.LOWERCASE_ROMAN]: (num: number) =>
    String(toRomanNumeral(num)).toLowerCase(),
  [MARKER_LIST_TYPES.UPPERCASE_ALPHABETIC]: (num: number) =>
    toAlpha(num).toUpperCase(),
  [MARKER_LIST_TYPES.LOWERCASE_ALPHABETIC]: (num: number) => toAlpha(num),
  [MARKER_LIST_TYPES.ARABIC]: (num: number) => String(num),
}

export class MarkerGenerator {
  listGenerators: ((num: any) => any)[]
  levelIndexes: Record<number, number> = {}

  constructor(listStyleLevels: HeaderOptionsType[] = []) {
    this.listGenerators = listStyleLevels.map(
      (listStyle) =>
        listGenerators[listStyle as unknown as keyof HeaderOptionsType] ||
        (() => null)
    )
  }

  incrementLevel(level: number) {
    let val = this.levelIndexes[level] ?? 0
    return (this.levelIndexes[level] = ++val)
  }

  reset(level: number) {
    this.levelIndexes[level] = 0
  }

  get(level: number) {
    let num = this.incrementLevel(level)
    return (
      this.listGenerators[
        level < this.listGenerators.length
          ? level
          : // NOTE Repeat list style series if not set for level
            level % this.listGenerators.length
      ] || ((n) => n)
    )(num)
  }
}
