import getContentHeaders from "./get-content-headers"

export default function isDroppablePathValid(droppable, currentPath = []) {
  let currentDepth = currentPath.length
  let droppableDepth =
    getContentHeaders(droppable.content).length +
    // If droppable is header, include itself in depth calculation
    (droppable.type === "HEADER" ? 1 : 0)

  return currentDepth + droppableDepth < 4
}
