export default class DocCounter {
  count = 0

  get() {
    return ++this.count
  }

  reset() {
    this.count = 0
  }
}
