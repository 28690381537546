import * as React from "react"

import {
  Button,
  Checkbox,
  HTMLSelect,
  Intent,
  Radio,
  RadioGroup,
  Switch,
} from "@blueprintjs/core"

import BinderFormatPreview from "./BinderFormatPreview"
import FormGroup from "./FormGroup"
import HeaderSelect from "./HeaderSelect"
import useForm from "./use-form"
import useSubmit from "./use-submit"
import DocCounter from "features/closing-binders/doc-counter"
import PageCounter from "features/closing-binders/page-counter"

export class DocCounterOptionsClass {
  constructor() {}
  get() {
    return {
      tabs: {
        label: "Tab",
        counter: new DocCounter(),
      },
      pages: {
        label: "Page",
        counter: new PageCounter(),
      },
      numbers: {
        label: "Number",
        counter: new DocCounter(),
      },
    }
  }
}

type BinderIndexFormatFormProps = {
  binder: Record<string, any>
  onCancel?: () => void
  onSuccess?: () => void
}

export default function BinderIndexFormatForm({
  onCancel = () => null,
  onSuccess = () => null,
  binder,
}: BinderIndexFormatFormProps) {
  let { handleSubmit, isLoading } = useSubmit(binder, { onSuccess })
  let { onFieldChange, values, isDirty } = useForm({
    defaultValues: { ...binder.formatStructure, saveAsDefault: false },
  })

  const docCounterOptions = new DocCounterOptionsClass().get()

  // If the index style is outline, we only want to show the first two options
  const docReferencingOptions =
    values.indexStyle === "outline"
      ? Object.keys(docCounterOptions).slice(0, 2)
      : Object.keys(docCounterOptions)

  return (
    <form className="mt-5 px-5 pb-4" onSubmit={handleSubmit(values)}>
      <fieldset disabled={isLoading}>
        <RadioGroup
          inline
          className="mb-2"
          onChange={(evt) => {
            onFieldChange("indexStyle", evt.currentTarget.value)
            if (evt.currentTarget.value === "outline") {
              onFieldChange("docNumbering", "pages")
            }
          }}
          selectedValue={values.indexStyle}
        >
          <Radio label="Table of contents" value="table_of_contents" />
          <Radio label="Outline" value="outline" />
        </RadioGroup>
        <FormGroup label="Primary header:">
          <HeaderSelect
            name="primaryHeader"
            value={values.primaryHeader}
            onChange={(val) => onFieldChange("primaryHeader", val)}
          />
        </FormGroup>
        <FormGroup label="Second header:">
          <HeaderSelect
            value={values.secondHeader}
            onChange={(val) => onFieldChange("secondHeader", val)}
          />
        </FormGroup>
        <FormGroup label="Third header:">
          <HeaderSelect
            value={values.thirdHeader}
            onChange={(val) => onFieldChange("thirdHeader", val)}
          />
        </FormGroup>
        {values.indexStyle === "outline" && (
          <FormGroup label="Last documents:">
            <HeaderSelect
              value={values.documentsHeader}
              onChange={(val) => onFieldChange("documentsHeader", val)}
            />
          </FormGroup>
        )}
        <div className="mb-3 flex items-center">
          <Switch
            className="m-0"
            checked={values.docNumbering !== "none"}
            id="docNumbering"
            onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
              onFieldChange(
                "docNumbering",
                evt.target.checked ? "tabs" : "none"
              )
            }
          />
          <label className="mr-2 flex-shrink-0" htmlFor="docNumbering">
            Document referencing:
          </label>
          <HTMLSelect
            className="w-full"
            disabled={values.docNumbering === "none"}
            onChange={(evt) => {
              onFieldChange("docNumbering", evt.target.value)
              if (evt.target.value !== "tabs") {
                onFieldChange("resetTabs", false)
              }
            }}
            value={values.docNumbering}
          >
            {docReferencingOptions.map((opt) => (
              <option value={opt} key={opt}>
                {opt.charAt(0).toUpperCase() + opt.slice(1)}
              </option>
            ))}
          </HTMLSelect>
        </div>
        {values.indexStyle !== "outline" && (
          <div className="mb-4 flex items-center">
            <Switch
              id="resetTabs"
              disabled={values.docNumbering !== "tabs"}
              className="m-0"
              checked={values.resetTabs}
              onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                onFieldChange("resetTabs", evt.target.checked)
              }
            />
            <label
              className={`mr-2 flex-shrink-0 ${
                values.docNumbering !== "tabs" ? "text-gray-350" : ""
              }`}
              htmlFor="resetTabs"
            >
              Reset tabs in each primary header
            </label>
          </div>
        )}
        <BinderFormatPreview
          {...values}
          docCounterOptions={docCounterOptions}
          listStyleFormatters={[
            values.primaryHeader,
            values.secondHeader,
            values.thirdHeader,
            values.documentsHeader,
          ]}
        />
        <div className="mt-4 flex">
          <label className="flex items-center">
            <Checkbox
              className="m-0"
              checked={values.saveAsDefault || false}
              onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                onFieldChange("saveAsDefault", evt.target.checked)
              }
            />{" "}
            Set as my default
          </label>
          <Button
            className="ml-auto mr-1"
            minimal
            onClick={onCancel}
            disabled={isLoading}
          >
            <div className="text-blue-9">Cancel</div>
          </Button>
          <Button
            type="submit"
            intent={Intent.PRIMARY}
            text="Save"
            disabled={!isDirty}
            loading={isLoading}
          />
        </div>
      </fieldset>
    </form>
  )
}
