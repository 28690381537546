import { encodeContent, useUpdateBinderContent } from "./api"
import { useSaveClosingBinderForm } from "./save-form"

export function useUpdateClosingBinderContent(binder) {
  let { save: saveContent } = useUpdateBinderContent()
  let { save: saveBinder } = useSaveClosingBinderForm({
    activeBinderId: binder.id,
  })

  async function save(contentInput) {
    if (binder.id === "new-binder") {
      let data = await saveBinder(binder)
      saveContent({ id: data.id, content: encodeContent(contentInput) })
    } else {
      saveContent({ id: binder.id, content: encodeContent(contentInput) })
    }
  }

  return { updateContent: save }
}
