import * as React from "react"

import { MarkerGenerator } from "features/closing-binders/apply-markers"

import PreviewNode from "./PreviewNode"
import { DocCounterOptionsClass } from ".."
import { HeaderOptionsType } from "../HeaderSelect"

const CONTENT = [
  {
    type: "HEADER",
    name: "Primary header",
    content: [
      { type: "ITEM", name: "Example Document" },
      {
        type: "HEADER",
        name: "Second header",
        content: [
          { type: "ITEM", name: "Example Document" },
          {
            type: "HEADER",
            name: "Third header",
            content: [{ type: "ITEM", name: "Example Document" }],
          },
        ],
      },
    ],
  },
]

export type PreviewNodeType = (typeof CONTENT)[0]

type BinderFormatPreviewProps = {
  listStyleFormatters?: HeaderOptionsType[]
  indexStyle?: "outline" | "table_of_contents"
  docNumbering: keyof ReturnType<DocCounterOptionsClass["get"]> | "none"
  docCounterOptions: ReturnType<DocCounterOptionsClass["get"]>
}

export default function BinderFormatPreview({
  listStyleFormatters,
  indexStyle,
  docNumbering,
  docCounterOptions,
}: BinderFormatPreviewProps) {
  let marker = new MarkerGenerator(listStyleFormatters)

  return (
    <div className="rounded border border-gray-300 px-2 py-1 text-gray-400 shadow-inner">
      <div className="flex h-5">
        {docNumbering !== "none" && docNumbering !== "numbers" && (
          <div className="ml-auto">
            {docCounterOptions[docNumbering]?.label}
          </div>
        )}
      </div>
      {CONTENT.map((node) => (
        <PreviewNode
          key={node.name}
          indexStyle={indexStyle}
          marker={marker}
          node={node}
          level={0}
          docCounter={
            docCounterOptions[
              docNumbering as keyof ReturnType<DocCounterOptionsClass["get"]>
            ]?.counter
          }
          docLabel={docNumbering}
        />
      ))}
    </div>
  )
}
