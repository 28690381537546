import * as React from "react"

import FileDownloadDialog from "components/notification/FileDownloadDialog"

type DownloadMenuProps = {
  children?: React.ReactNode
  downloadUrls?: any
  zip_file?: string
  pdf_file?: string
  id?: string
  extraData?: any
  type?: string
  linkText?: string
  meta?: React.ReactNode
}

export function DownloadMenu({
  children,
  downloadUrls,
  ...rest
}: DownloadMenuProps) {
  let [isOpen, setIsOpen] = React.useState(false)

  function onClick() {
    setIsOpen(true)
  }

  let childProps: { onClick: () => void } = {
    onClick,
  }

  return (
    <>
      {React.isValidElement(children) &&
        React.cloneElement(children, childProps)}
      {isOpen && (
        <FileDownloadDialog
          {...rest}
          isOpen
          downloadUrls={downloadUrls}
          onClose={() => setIsOpen(false)}
        />
      )}
    </>
  )
}
