import { Fragment, useState } from "react"
import { v4 as uuid } from "uuid"
import styled from "@emotion/styled"
import { Colors, Checkbox, Icon, Menu } from "@blueprintjs/core"

import { useDrop } from "react-dnd"

import {
  deleteItemAtPath,
  insertItemAtPath,
  moveItemToPath,
  reduceIntoKeyByValue,
  sortByPath,
  toggleItem,
} from "helpers/array"
import { sortByKey } from "helpers/array/sort"
import { wordsMatch } from "helpers/match"

import BinderEmptyStateIcon from "icons/empty-state-closing-binder.svg"

import BinderConfigMenu from "features/closing-binders/BinderConfigMenu"
import BuildStatusCard from "features/closing-binders/BuildStatusCard"
import applyMarkersToContent from "features/closing-binders/apply-markers"
import scrubInvalidContent from "features/closing-binders/scrub-invalid-content"
import { decodeContent } from "features/closing-binders/api"
import { useCurrentUser } from "features/auth/withAuth"
import { useIsAllExpanded } from "features/closing-binders/expanded-headers"
import { useTriggerBuildWatch } from "features/closing-binders/build-watch"
import { useUpdateClosingBinderContent } from "features/closing-binders/save-content"
import { useExpandHeader } from "features/closing-binders/expanded-headers"

import SortMenu, { SortDirectionType } from "components/menus/SortMenu"
import { SearchInput } from "components/forms"
import { MenuItem2 } from "@blueprintjs/popover2"

import { cEventTrack } from "app/user-tracking/index"

import AddHeader from "./AddHeader"
import DraggableItemCard from "components/closing-binder/DraggableItemCard"
import IndexHeaderCard from "./IndexHeaderCard"
import IndexItemCard from "./IndexItemCard"
import { BinderItemCard } from "."
import BuildBinderButton from "./BuildBinderButton"
import getContentHeaders from "./get-content-headers"
import { sortItemsByVisualElementOrder } from "features/transaction/id/DashboardMain/TransactionElements/visual-order-element-ids"
import { useTransactionElements } from "features/transaction/id/api"

const DropContainer = styled.div<{ isOver?: boolean }>`
  counter-reset: closing-binder-counter;
  border-radius: 0.25rem;
  ${(props) =>
    props.isOver &&
    `background: rgba(189, 107, 189, 0.3);`}; /* Colors.VIOLET4 at 70% transparency*/
`

export const InsertBar = styled.div<{ depth?: number }>`
  border: 1px solid ${Colors.VIOLET4};
  top: -1px;
  pointer-events: none;
  position: absolute;
  width: calc(100% + 0.5rem);
  margin-left: -0.25rem;
  z-index: ${(props) => (props.depth || 0) - 1};

  &:before {
    content: " ";
    display: block;
    position: absolute;
    top: -0.25rem;
    left: -0.5rem;
    height: 0.5rem;
    width: 0.5rem;
    border-radius: 50%;
    background-color: ${Colors.VIOLET4};
  }

  &:after {
    content: " ";
    display: block;
    position: absolute;
    top: -0.25rem;
    right: -0.5rem;
    height: 0.5rem;
    width: 0.5rem;
    border-radius: 50%;
    background-color: ${Colors.VIOLET4};
  }
`

const AvailableList = styled.div<{ isOver?: boolean }>`
  border-radius: 0.25rem;
  border: 1px solid transparent;

  ${(props) =>
    props.isOver &&
    /* Colors.VIOLET4 at 70% transparency */
    `background: rgba(189, 107, 189, 0.3); 
    border: 1px solid ${Colors.VIOLET4};
    `}
`

function editContentHeader(header, contentInput = []) {
  function applyEdit(content) {
    return content.reduce((data, node) => {
      if (node.type === "HEADER") {
        if (node.id === header.id) {
          return [...data, header]
        }
        return [...data, { ...node, content: applyEdit(node.content) }]
      }

      return [...data, node]
    }, [])
  }

  return applyEdit(contentInput)
}

function deleteContentHeader(id, contentInput = []) {
  function applyDelete(content) {
    return content.reduce((data, node) => {
      if (node.type === "HEADER") {
        if (node.id === id) {
          return [...data, ...node.content]
        }
        return [...data, { ...node, content: applyDelete(node.content) }]
      }

      return [...data, node]
    }, [])
  }

  return applyDelete(contentInput)
}

function contentItems(content = []) {
  return content.reduce((items, node) => {
    if (node.type === "HEADER") {
      return [...items, ...contentItems(node.content)]
    }

    if (node.type === "ITEM") {
      return [...items, node]
    }

    return items
  }, [])
}

function contentItemIds(content = []) {
  return contentItems(content).map((i) => i.id)
}

function findPathForNode(node, content = [], path = []) {
  for (const [index, obj] of content.entries()) {
    if (obj.id === node.id) {
      return [...path, index]
    }

    if (obj.type === "HEADER") {
      const nodePath = findPathForNode(node, obj.content, [...path, index])
      if (nodePath) {
        return nodePath
      }
    }
  }
  return null
}

function applyPathsToContent(content = [], path = []) {
  return content.map((node, index) => {
    const nodePath = [...path, index]

    if (node.type === "HEADER") {
      return {
        ...node,
        path: nodePath,
        content: applyPathsToContent(node.content, nodePath),
      }
    }

    return { ...node, path: nodePath }
  })
}

function applySelections(content = [], selectedItemIds = []) {
  return content.map((node) => {
    if (node.type === "HEADER") {
      return {
        ...node,
        content: applySelections(node.content, selectedItemIds),
      }
    }

    return { ...node, isSelected: selectedItemIds.includes(node.id) }
  })
}

const sorts = {
  none: sortByKey("elementOrder", "asc", "number"),
  ASC: sortByKey("name"),
  DESC: sortByKey("name", "desc"),
}

function ItemsSortFor(sortType: SortDirectionType) {
  return sorts[sortType === null ? "none" : sortType] || (() => 0)
}

type BinderBlockProps = {
  binder: Record<string, any>
  content?: any[]
  items?: any[]
  transactionId: string
}

export default function BinderBlock({
  binder,
  content: contentInput,
  items = [],
  transactionId,
}: BinderBlockProps) {
  const binderId = binder.id
  const { data: elements } = useTransactionElements(transactionId)

  const [availableSearchTerm, setAvailableSearchTerm] = useState("")
  const [availableItemsSort, setAvailableItemsSort] =
    useState<SortDirectionType>(null)
  const [dropIndex, setDropIndex] = useState(null)
  const [expandHeader] = useExpandHeader()

  const content = applyPathsToContent(
    applyMarkersToContent(
      decodeContent(contentInput, reduceIntoKeyByValue(items)),
      binder.formatStructure
    )
  )
  const [canDrop, setCanDrop] = useState(true)
  const [selectedItemIds, setSelectedItemIds] = useState([])

  const itemsById = reduceIntoKeyByValue(items)
  const contentItemsById = reduceIntoKeyByValue(contentItems(content))
  const itemsIdsInIndex = contentItemIds(content)
  const filteredItems = items
    .filter((i) => !itemsIdsInIndex.includes(i.id))
    .filter((i) => !i.pagesEmbeddedIn)
    .filter((i) =>
      !availableSearchTerm ? true : wordsMatch(availableSearchTerm, i.name)
    )
  let availableItems = filteredItems
  if (!availableItemsSort) {
    availableItems = sortItemsByVisualElementOrder(
      filteredItems,
      elements || []
    )
  } else {
    availableItems = filteredItems.sort(ItemsSortFor(availableItemsSort))
  }

  const headers = getContentHeaders(content)
  const [isAllExpanded, setExpandAllHeaders] = useIsAllExpanded(
    headers.map(({ id }) => id)
  )
  let { triggerBuildWatch, status: buildStatus } = useTriggerBuildWatch()

  const { currentUser } = useCurrentUser()

  let { updateContent } = useUpdateClosingBinderContent(binder)

  function setContent(content) {
    return updateContent(
      applyPathsToContent(
        applyMarkersToContent(
          scrubInvalidContent(content, { items }),
          binder.formatStructure
        )
      )
    )
  }

  function handleDrop(item) {
    handleInsertAtPath(
      dropIndex === null ? [content.length] : [dropIndex],
      item
    )
    setDropIndex(null)
  }

  function handleDropOnAvailable(item) {
    if (selectedItemIds.includes(item.id)) {
      setContent(
        // This works because the selectedItemIds are stored in desc path order
        // so we can loop through and delete the item at the path because it won't
        // affect any paths higher up in the content tree.
        selectedItemIds.reduce((data, itemId) => {
          const itemToDelete = contentItems(applyPathsToContent(data)).find(
            (i) => i.id === itemId
          )
          if (itemToDelete) {
            return deleteItemAtPath(data, itemToDelete.path, "content")
          }
          return data
        }, content)
      )
      setSelectedItemIds([])
    } else {
      setContent(deleteItemAtPath(content, item.path, "content"))
    }
    setDropIndex(null)
  }

  function handleInsertAtPath(path, item) {
    let data = [...content]
    let dropPath = path
    const originalDropIndex = path[path.length - 1]
    if (selectedItemIds.includes(item.id)) {
      selectedItemIds.forEach((itemId, index) => {
        const isInIndex = itemsIdsInIndex.includes(itemId)

        if (isInIndex) {
          const selectedItem = contentItems(applyPathsToContent(data)).find(
            (i) => i.id === itemId
          )

          const [newContent, updatedDropPath] = moveItemToPath(
            data,
            [...dropPath.slice(0, -1), dropPath[dropPath.length - 1]],
            { ...selectedItem, type: "ITEM" },
            "content"
          )

          data = newContent
          dropPath = updatedDropPath

          return
        } else {
          const selectedItem = itemsById[itemId]

          data = insertItemAtPath(
            data,
            [...dropPath.slice(0, -1), originalDropIndex + index],
            { ...selectedItem, type: "ITEM" },
            "content"
          )
        }
      })
      setSelectedItemIds([])
    } else {
      data = moveItemToPath(data, path, item, "content")[0]
    }
    setContent(data)
  }

  function handleSetHoverIndex(index) {
    setDropIndex(index)
  }

  function handleCancel() {
    setDropIndex(null)
  }

  function handleDeleteHeader(id) {
    setContent(deleteContentHeader(id, content))
  }

  function handleEditHeader(header) {
    setContent(editContentHeader(header, content))
  }

  function handleAddheader(header) {
    setContent([
      ...content,
      { ...header, type: "HEADER", id: uuid(), content: [] },
    ])
  }

  function handleBuildSuccess(buildBinderId) {
    triggerBuildWatch(buildBinderId)
    cEventTrack(currentUser, "BINDER_CREATED", {
      transactionId: transactionId,
      numberOfDocuments: Object.keys(contentItemsById).length,
    })
  }

  function handleMoveToHeader(header) {
    const path = findPathForNode(header, content)
    const firstSelectedItemId = selectedItemIds[0]
    if (path) {
      handleInsertAtPath([...path, header.content.length], {
        id: firstSelectedItemId,
      })
    }
    expandHeader(header.id)
  }

  function handleIsOverChildHeader(isOverHeader) {
    if (isOverHeader) {
      setDropIndex(null)
    }
    setCanDrop(!isOverHeader)
  }

  function handleToggleSelection(itemId) {
    // NOTE If selecting an item in a different list than existing selected
    // items, deselect existing
    let isItemIdInIndex = itemsIdsInIndex.includes(itemId)
    let isItemAlreadySelected = selectedItemIds.includes(itemId)
    let isAllSelectedInIndex = selectedItemIds.every((id) =>
      itemsIdsInIndex.includes(id)
    )
    let isDeselectingExistingList =
      (isItemIdInIndex && !isAllSelectedInIndex) ||
      (!isItemIdInIndex && isAllSelectedInIndex)

    if (selectedItemIds.length === 0 || isDeselectingExistingList)
      return setSelectedItemIds([itemId])

    if (!isItemIdInIndex && !isItemAlreadySelected) {
      const tempSelectedIds = [...selectedItemIds, itemId]
      const sortOrderListed = availableItems.filter((i) =>
        tempSelectedIds.includes(i.id)
      )
      const sortedSelectedIds = sortOrderListed.map(({ id }) => id)

      return setSelectedItemIds(sortedSelectedIds)
    }

    setSelectedItemIds(
      // NOTE Properly sort selected items based on path in index so that dnd out
      // of index will work properly
      sortByPath(
        toggleItem(selectedItemIds, itemId).map(
          (id) => contentItemsById[id] || itemsById[id]
        ),
        "desc"
      ).map(({ id }) => id)
    )
  }

  function handleToggleSelectAll(evt) {
    const isAllSelected = evt.target.checked
    if (isAllSelected) {
      setSelectedItemIds(availableItems.map((i) => i.id))
    } else {
      setSelectedItemIds([])
    }
  }

  const [{ isOver }, dropRef] = useDrop({
    accept: ["ITEM", "HEADER"],
    drop: handleDrop,
    collect: (monitor) => ({ isOver: monitor.isOver() }),
    canDrop: () => canDrop,
  })

  const [{ isOver: isOverAvailableList }, availableListDropRef] = useDrop({
    accept: ["ITEM"],
    drop: handleDropOnAvailable,
    collect: (monitor) => ({ isOver: monitor.isOver() && monitor.canDrop() }),
    canDrop: (item) => item.inBinder,
  })

  const isShowingHoverUI = isOver && canDrop

  return (
    <>
      <DraggableItemCard numDraggingDocuments={selectedItemIds.length} />
      <div ref={dropRef} className="flex w-full flex-col overflow-hidden">
        <div className="px-3">
          <div className="items-center lg:flex">
            <h3 className="m-0 flex-1">Binder Index</h3>
            <div className="ml-auto flex space-x-2">
              <BinderConfigMenu binder={{ ...binder, content }} />
              <AddHeader onCreateHeader={handleAddheader} />
              <BuildBinderButton
                binderId={binderId}
                transactionId={transactionId}
                isDisabled={
                  buildStatus === "WATCHING" ||
                  contentItems(content).length === 0
                }
                onBuildSuccess={handleBuildSuccess}
              />
            </div>
          </div>
          <div className="overflow-hidden">
            {binderId !== "new-binder" && (
              <BuildStatusCard binderId={binderId} />
            )}
          </div>
          <div className="mt-2 flex justify-end">
            <a
              className="color-gray-1 text-xxs"
              onClick={() => setExpandAllHeaders(isAllExpanded ? false : true)}
            >
              {isAllExpanded ? (
                <>
                  Collapse all <Icon icon="chevron-up" />
                </>
              ) : (
                <>
                  Expand all <Icon icon="chevron-down" />
                </>
              )}
            </a>
          </div>
        </div>
        <DropContainer
          isOver={isShowingHoverUI && content.length === 0}
          className="flex-grow overflow-auto px-3"
        >
          {content.length === 0 && (
            <div className="flex flex-col p-12 text-center">
              <BinderEmptyStateIcon
                height="200"
                width="200"
                viewBox="0 0 500 500"
                className="mx-auto"
              />
              <h3 className="my-4">Start a closing binder</h3>
              <div className="color-gray-1">
                Drag available documents here to add them to your binder. Use
                the list icon to create sections, like headers and subheaders.
              </div>
            </div>
          )}
          {applySelections(content, selectedItemIds).map((node, index) => {
            const Component =
              node.type === "HEADER" ? IndexHeaderCard : IndexItemCard

            /* NOTE dont yet have items so no node.id*/
            return (
              <Fragment key={node.id}>
                <div className="relative">
                  {true && index === dropIndex && <InsertBar />}
                  <Component
                    {...node}
                    binderId={binderId}
                    transactionId={transactionId}
                    isOverParent={isOver}
                    index={index}
                    setHoverIndex={handleSetHoverIndex}
                    onCancel={handleCancel}
                    onEdit={handleEditHeader}
                    onDelete={handleDeleteHeader}
                    onIsOverHeader={handleIsOverChildHeader}
                    onInsertAtPath={handleInsertAtPath}
                    selectedItemIds={selectedItemIds}
                    onToggleSelection={handleToggleSelection}
                    headers={headers}
                    onSendTo={handleMoveToHeader}
                  />
                </div>
              </Fragment>
            )
          })}
          <div className="relative py-1">
            {isShowingHoverUI &&
              content.length > 0 &&
              (dropIndex === content.length || dropIndex === null) && (
                <InsertBar />
              )}
          </div>
          <div className="py-4" />
        </DropContainer>
      </div>
      <AvailableList
        className="flex h-full flex-col overflow-hidden px-1"
        ref={availableListDropRef}
        isOver={isOverAvailableList}
      >
        <div className="mb-2 flex flex-shrink-0 items-center">
          <Checkbox
            className="m-0"
            checked={
              availableItems.length !== 0 &&
              availableItems.every((i) => selectedItemIds.includes(i.id))
            }
            onChange={handleToggleSelectAll}
          />
          <h3 className="m-0">Available Documents</h3>
        </div>
        <div className="mb-3 flex flex-shrink-0">
          <SearchInput
            className="mr-3"
            value={availableSearchTerm}
            onChange={setAvailableSearchTerm}
            onClear={() => setAvailableSearchTerm("")}
            placeholder="Search by document"
          />
          <SortMenu
            sortType={availableItemsSort}
            onSortTypeChange={setAvailableItemsSort}
            tooltipContent="Sort documents"
          >
            <Menu>
              <MenuItem2
                text="Alphabetical (A - Z)"
                disabled={availableItemsSort === "ASC"}
                onClick={() => {
                  setAvailableItemsSort("ASC")
                }}
              />
              <MenuItem2
                text="Alphabetical (Z - A)"
                disabled={availableItemsSort === "DESC"}
                onClick={() => {
                  setAvailableItemsSort("DESC")
                }}
              />
            </Menu>
          </SortMenu>
        </div>
        <div className="flex-grow overflow-auto pb-20">
          {availableItems.map((item) => (
            <div className="mb-2" key={item.id}>
              <BinderItemCard
                {...item}
                transactionId={transactionId}
                headers={headers}
                onCancel={handleCancel}
                isSelected={selectedItemIds.includes(item.id)}
                onToggleSelection={handleToggleSelection}
                onSendTo={handleMoveToHeader}
              />
            </div>
          ))}
        </div>
      </AvailableList>
    </>
  )
}

export { BinderBlock }
