import { useState, useEffect } from "react"
import { Intent } from "@blueprintjs/core"
import { Tooltip2 } from "@blueprintjs/popover2"

import BuildIcon from "icons/build.svg"

import IndexHeaderBtn from "features/closing-binders/IndexHeaderBtn"
import useToaster from "helpers/toaster"
import { useBuildBinder } from "features/closing-binders/api"
import UserEngagement from "app/user-engagement"
import ColorSpinner from "components/spinners/ColorSpinner"

type BuildBinderButtonProps = {
  isDisabled?: boolean
  binderId: string
  transactionId: string
  onBuildSuccess?: (id: string) => void
}

export default function BuildBinderButton({
  isDisabled,
  binderId,
  onBuildSuccess,
  transactionId,
}: BuildBinderButtonProps) {
  const { failure: toasterFailure, success } = useToaster()
  const [isBuilding, setIsBuilding] = useState(false)
  const { buildBinder, error, data, isLoading } = useBuildBinder()

  useEffect(() => {
    if (error && !isLoading) {
      toasterFailure(
        "Sorry, we ran into an error attempting to build the closing binder."
      )
    }
    if (isLoading && !error) {
      onBuildSuccess?.(binderId)
      success(
        "We're creating your closing binder now. You'll be notified when it is ready for download."
      )
    }
    setIsBuilding(false)
  }, [error, data, isLoading])

  function handleClick() {
    setIsBuilding(true)

    UserEngagement.track("BUILD_BINDER_CLICKED", {
      transactionId,
      binderId,
    })

    buildBinder(binderId)
  }

  return (
    <Tooltip2 content="Generate the compiled binder" disabled={isDisabled}>
      <IndexHeaderBtn
        isDisabled={isDisabled}
        label="Build"
        icon={
          isBuilding ? (
            <ColorSpinner color="white" size={14} intent={Intent.PRIMARY} />
          ) : (
            <BuildIcon viewBox="-2 1 23 23" />
          )
        }
        onClick={handleClick}
      />
    </Tooltip2>
  )
}
