import * as React from "react"
import { isEqualValues } from "helpers/object"

export default function useForm(options = {}) {
  let [values, setValues] = React.useState(options.defaultValues || {})

  let onFieldChange = React.useCallback(
    (field, val) => {
      setValues((prev) => ({ ...prev, [field]: val }))
    },
    [setValues]
  )

  let result = React.useMemo(
    () => ({
      onFieldChange,
      values,
      isDirty: !isEqualValues(values, options.defaultValues),
    }),
    [onFieldChange, values]
  )

  return result
}
