import classNames from "classnames"
import { Button } from "@blueprintjs/core"
import { Tooltip2 } from "@blueprintjs/popover2"

import AddDocumentIcon from "icons/add-document.svg"

type AddDocumentBtnProps = {
  handleAddDocument: () => void
  tooltipPlacement?: "top" | "top-start"
  isSupplemental?: boolean
}

export default function AddDocumentBtn({
  handleAddDocument,
  tooltipPlacement = "top",
  isSupplemental = false,
}: AddDocumentBtnProps) {
  let btnClassNames = classNames(
    "h-full min-h-[38px] max-w-[40px] !shadow-none",
    {
      "!bg-gray-50 hover:!bg-gray-100": isSupplemental,
      "!bg-white hover:!bg-gray-50": !isSupplemental,
    }
  )
  return (
    <Tooltip2
      content="Upload document to this placeholder"
      hoverOpenDelay={500}
      placement={tooltipPlacement}
    >
      <Button
        style={{
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          borderLeft: "1px solid #dbdcdd",
        }}
        icon={<AddDocumentIcon className="!mr-0.5" />}
        className={btnClassNames}
        onClick={(e) => {
          e.stopPropagation()
          handleAddDocument()
        }}
        data-add-document-btn
        data-testid="add-document-btn"
      />
    </Tooltip2>
  )
}
