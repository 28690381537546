import * as React from "react"
import Router from "next/router"

import {
  apiClient,
  useAPI,
  useAPIMutation,
  useCachedAPIMutation,
  useOnSuccessAPIMutation,
} from "lib/api"

import { mergeOrAddItem } from "helpers/array"
import { useTransactionIdParam } from "helpers/params"
import {
  createOrUpdateBinder,
  deleteBinder,
  getClosingBinderTemplates,
  getClosingBinders,
  postBuildBinder,
  postCancelBuildBinder,
} from "helpers/api"
import { useTransactionIdQueryParam } from "helpers/query-string/useQueryParams"
import { reduceIntoKeyByValue } from "helpers/array"

import {
  formatClosingBinderContentToAPI,
  formatClosingBinderToAPI,
} from "app/api/graphql/resolvers/formatters"
import { decode as decodeClosingBinder } from "models/ClosingBinder"
import { decode as decodeClosingBinderTemplate } from "models/ClosingBinderTemplate"

import { useTransactionItems } from "features/transaction/id/api"
import { QueryOptions } from "models/QueryOptions"

/* NOTE Example data from API
 *
 * const esquire = {
 *   content: [
 *     {
 *       category: "header",
 *       name: "Header A",
 *       content: [
 *         {
 *           category: "header",
 *           name: "Header A-1",
 *           content: [
 *             { category: "item", pk: "30077412-eb2f-461b-ab75-1bf899432b29" },
 *             { category: "item", pk: "81d1a1e0-418a-45aa-a6e2-51571abfa4ee" },
 *           ],
 *           id: "1597800685",
 *         },
 *       ],
 *       id: "1597800657",
 *     },
 *   ],
 * };
 */

export function decodeContent(content = [], itemsById = {}) {
  return content.map(
    ({
      id,
      pk,
      name,
      category,
      content,
      marker,
      attachments_for_index: attachmentIdsForIndex,
    }) => {
      if (category === "header") {
        return {
          type: "HEADER",
          id,
          name,
          content: decodeContent(content, itemsById),
          marker,
        }
      }

      return {
        ...itemsById[pk],
        id: pk,
        marker,
        attachmentIdsForIndex,
        type: "ITEM",
      }
    }
  )
}

function encodeHeader(header) {
  return {
    id: header.id,
    category: "header",
    content: encodeContent(header.content),
    marker: header.marker,
    name: header.name,
  }
}

function encodeItem(item) {
  return {
    id: item.id,
    category: "item",
    marker: String(item.marker),
    attachments_for_index: item.attachmentIdsForIndex,
  }
}

export function encodeContent(content = []) {
  return content.map((node) => {
    if (node.type === "HEADER") {
      return encodeHeader(node)
    }
    return encodeItem(node)
  })
}

function updateContentNode(content, id, fields = {}) {
  return content.map((node) => {
    if (node.id === id) {
      return { ...node, ...fields }
    }

    if (node.type === "HEADER") {
      return { ...node, content: updateContentNode(node.content, id, fields) }
    }

    return node
  })
}

export function useUpdateBinderField() {
  let transactionId = useTransactionIdParam()
  let key = ["transactions", transactionId, "closing-binders"]

  let result = useOnSuccessAPIMutation(
    key,
    (vars) => createOrUpdateBinder(vars.id, formatClosingBinderToAPI(vars)),
    {
      cacheUpdaterFn: (cbData, cachedCbs) => {
        return mergeOrAddItem(cachedCbs, cbData, "uuid")
      },
      errorMsg:
        "We ran into a problem saving the closing binder. Please try again or reach out to support.",
    }
  )

  let fn = (binderId, fields) => result.mutate({ ...fields, id: binderId })

  return { ...result, updateBinderField: fn }
}

export function useCreateOrUpdateBinder() {
  let transactionId = useTransactionIdParam()
  let key = ["transactions", transactionId, "closing-binders"]

  let result = useOnSuccessAPIMutation(
    key,
    (vars) => createOrUpdateBinder(vars.id, formatClosingBinderToAPI(vars)),
    {
      cacheUpdaterFn: (cbData, cachedCbs) => {
        return mergeOrAddItem(cachedCbs, cbData, "uuid")
      },
      errorMsg:
        "We ran into a problem saving the closing binder. Please try again or reach out to support.",
    }
  )

  async function saveAsync(args) {
    return decodeClosingBinder(await result.mutateAsync(args))
  }

  return { ...result, save: saveAsync }
}

export function useUpdateBinderContent() {
  let transactionId = useTransactionIdParam()
  let key = ["transactions", transactionId, "closing-binders"]

  let result = useCachedAPIMutation(
    key,
    ({ id, content }) =>
      createOrUpdateBinder(id, {
        content: formatClosingBinderContentToAPI(content),
      }),
    {
      cacheUpdaterFn: (cbInput, cachedCbs) => {
        let formattedContent = formatClosingBinderContentToAPI(cbInput.content)
        return cachedCbs?.map((binder) => {
          if (binder.uuid === cbInput.id) {
            return { ...binder, content: formattedContent }
          }
          return binder
        })
      },
    }
  )

  return { ...result, save: result.mutate }
}

export function useUpdateBinderContentNode() {
  let updateContent = useUpdateBinderContent()

  let updateNode = (binderId, itemId, fields = {}) => {
    let transactionId = Router.query.id
    let queryKey = ["transactions", transactionId, "closing-binders"]
    let content = apiClient
      .getQueryData(queryKey)
      ?.find((cb) => cb.uuid === binderId)?.content

    if (content) {
      updateContent.save({
        id: binderId,
        content: encodeContent(
          updateContentNode(decodeContent(content), itemId, fields)
        ),
      })
    }
  }

  return { updateNode }
}

export function useBuildBinder() {
  let result = useAPIMutation((binderId: string) => postBuildBinder(binderId))

  return { ...result, buildBinder: result.mutate }
}

export function useCancelBuildBinder() {
  let result = useAPIMutation((binderId: string) =>
    postCancelBuildBinder(binderId)
  )

  return { ...result, cancelBuildBinder: result.mutate }
}

export function useDeleteBinder({ activeBinderId, ...options }) {
  let transactionId = useTransactionIdParam()
  let key = ["transactions", transactionId, "closing-binders"]

  let result = useCachedAPIMutation(key, (binderId) => deleteBinder(binderId), {
    cacheUpdaterFn: (cbInput, cachedCbs) => {
      return cachedCbs?.filter((binder) => binder.uuid !== cbInput)
    },
    ...options,
  })

  let fn = () => result.mutate(activeBinderId)

  return { ...result, deleteBinder: fn }
}

export function useBinderTemplates(transactionIdInput?: string) {
  let transactionId = String(useTransactionIdQueryParam() || transactionIdInput)

  return useAPI(
    ["transactions", transactionId, "closing-binder-templates"],
    () => getClosingBinderTemplates(transactionId),
    {
      select: (templates) =>
        templates ? templates.map(decodeClosingBinderTemplate) : undefined,
    }
  )
}

export function useTransactionClosingBinders(
  id: string,
  queryOptions: QueryOptions = {}
) {
  return useAPI(
    ["transactions", id, "closing-binders"],
    () => getClosingBinders(id),
    {
      select: (data) => data?.map(decodeClosingBinder),
      ...queryOptions,
    }
  )
}

export function useClosingBinders(transactionIdInput: string) {
  let transactionId = (useTransactionIdQueryParam() ||
    transactionIdInput) as string

  let itemsQuery = useTransactionItems(transactionId)
  let closingBindersQuery = useTransactionClosingBinders(transactionId)

  let queries = [itemsQuery, closingBindersQuery]

  let isAnyLoading = queries.some((q) => q.isLoading)
  let isAnyRefetching = queries.some((q) => q.isFetching && !q.isLoading)

  let error = queries.find((q) => q.error)

  let result = React.useMemo(() => {
    let itemsData = itemsQuery.data || []
    let itemsById = reduceIntoKeyByValue(itemsData)

    let items = itemsData.map((item) => ({
      ...item,
      pages: item.pages.map((page) => ({
        ...page,
        items: page.items
          ? page.items.map((itemId) => itemsById[itemId])
          : undefined,
      })),
    }))

    return {
      isLoading: isAnyLoading,
      isRefetching: isAnyRefetching,
      data: { items, binders: closingBindersQuery.data },
      error,
    }
  }, [
    isAnyLoading,
    isAnyRefetching,
    itemsQuery.data,
    closingBindersQuery.data,
    error,
  ])

  return result
}
