import * as React from "react"

type FormGroupProps = {
  label?: React.ReactNode
  children?: React.ReactNode
}

export default function FormGroup({ label, children }: FormGroupProps) {
  return (
    <label className="mb-3 flex items-center">
      <div className="mr-2 w-1/3 flex-shrink-0">{label}</div>
      {children}
    </label>
  )
}
