import * as React from "react"

type Header1Props = {
  children?: React.ReactNode
  className?: string
}

export default function Header1({ children, className = "" }: Header1Props) {
  return <h1 className={`${className} text-2.5xl tracking-1`}>{children}</h1>
}
