import * as React from "react"

import cx from "classnames"
import { Icon, Intent, ProgressBar } from "@blueprintjs/core"

import WhiteboardSvg from "icons/whiteboard.svg"

import LightBox from "components/LightBox"

import PagePreviews from "./PagePreviews"
import { useBinderIndexPreview } from "./api"
import useProgressValue from "./use-progress-value"
import { useDocument } from "features/document/api"

type BinderIndexPreviewModalProps = {
  binderId: string
  workingGroupDocumentId: string
  onClose?: () => void
}

export default function BinderIndexPreviewModal({
  binderId,
  workingGroupDocumentId,
  onClose = () => null,
}: BinderIndexPreviewModalProps) {
  let { loading, error, data } = useBinderIndexPreview(binderId)
  let { data: workingGroupdata } = useDocument(workingGroupDocumentId)
  let progressValue = useProgressValue()

  let wrapperClasses = cx("bg-white", {
    "pt-12 px-12 pb-4 rounded": loading,
    "h-[calc(100vh-20vh)] aspect-ratio-page": !loading,
  })

  return (
    <LightBox onClickOutside={onClose}>
      <div className={wrapperClasses}>
        {loading ? (
          <div className="h-full w-[350px]">
            <WhiteboardSvg className="mx-auto" />
            <div className="mx-auto mt-4 overflow-hidden rounded border border-gray-200 text-center">
              <p className="pb-2 pt-3 text-sm">
                Building the index now—ready in a moment!
              </p>
              <div>
                <ProgressBar
                  className="sa-progress-bar"
                  value={progressValue}
                />
              </div>
            </div>
          </div>
        ) : error ? (
          <div className="flex h-full w-full flex-col items-center justify-center">
            <Icon icon="warning-sign" size={48} intent={Intent.DANGER} />
            <p className="mt-4 px-8 text-center">
              There was a problem generating the preview. Please try again or
              reach out to support.
            </p>
          </div>
        ) : (
          <PagePreviews
            onClose={onClose}
            coverPageUrl={data.cover_page_pdf}
            pdfUrl={data.index_pdf}
            wordUrl={data.index_docx}
            workingGroupPageUrl={workingGroupdata?.pdfFileUrl}
          />
        )}
      </div>
    </LightBox>
  )
}
