import * as React from "react"

function getRandomProgress() {
  return Math.random() * (0.3 - 0.01) + 0.01
}

export default function useProgressValue(max = 0.9, interval = 1100) {
  let [currentValue, setCurrentValue] = React.useState(0.1)

  React.useEffect(() => {
    let ticker = setTimeout(() => {
      if (currentValue < max) {
        setCurrentValue(currentValue + getRandomProgress())
      }
    }, interval)

    return () => clearTimeout(ticker)
  }, [max, interval, currentValue])

  return currentValue
}
