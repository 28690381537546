/*
|-----------------------------------------------------------------------------
| NOTE - importing from 'legacy' build
|-----------------------------------------------------------------------------
|
| The main pdfjs-dist package uses Promise.withResolvers and that is not 
| supported in node v20 and errors when building the app in next.js
|
*/

import * as PDFJS from "pdfjs-dist/legacy/build/pdf.mjs"

PDFJS.GlobalWorkerOptions.workerSrc = "/static/pdf.worker.min.4.6.82.mjs"

import PDFDocument from "./document"

export { PDFDocument }

export default PDFJS
