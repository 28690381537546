import { Dispatch, ReactNode, SetStateAction } from "react"
import { Position, Tag } from "@blueprintjs/core"
import { Popover2, Tooltip2 } from "@blueprintjs/popover2"
import styled from "@emotion/styled"
import SortAscIcon from "icons/sort-asc.svg"
import SortDescIcon from "icons/sort-desc.svg"

const SortTag = styled(Tag)`
  padding: 5px 7px 5px 7px;
  min-width: 68px;

  .bp4-fill {
    font-size: 16px;
  }

  .bp4-icon svg {
    height: 20px;
    width: 20px;
  }
`

export type SortDirectionType = "DESC" | "ASC" | null

type SortMenuProps = {
  onSortTypeChange: Dispatch<SetStateAction<SortDirectionType>>
  sortType: SortDirectionType
  tooltipContent: string
  children: ReactNode
}

export default function SortMenu({
  onSortTypeChange,
  sortType,
  tooltipContent,
  children,
}: SortMenuProps) {
  return (
    <>
      <Popover2 placement="bottom-start" content={<>{children}</>} minimal>
        <Tooltip2
          content={tooltipContent}
          placement={Position.TOP}
          hoverOpenDelay={500}
        >
          <button
            data-testid="sort-menu-btn"
            className="w-8 h-8 flex items-center justify-center rounded-full bg-white border hover:bg-[#8F99A826] active:bg-[#8F99A84D] transition-all duration-200 ease-in-out"
          >
            {sortType === "ASC" ? <SortAscIcon /> : <SortDescIcon />}
          </button>
        </Tooltip2>
      </Popover2>
      {sortType && (
        <SortTag
          intent="primary"
          minimal
          className="ml-1"
          onRemove={() => onSortTypeChange(null)}
        >
          {sortType === "ASC" ? "A - Z" : "Z - A"}
        </SortTag>
      )}
    </>
  )
}
