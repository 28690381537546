import { useTransactionIdParam } from "helpers/params"

import { useCreateOrUpdateBinder } from "./api"

export function useSaveClosingBinderForm({
  activeBinderId,
  onSuccess = () => null,
}) {
  let transactionId = useTransactionIdParam()
  let { save: saveBinder, isLoading, error } = useCreateOrUpdateBinder()

  async function save(binderData) {
    const isNewBinder =
      !activeBinderId ||
      activeBinderId === "new-binder" ||
      binderData?.id === "new-binder"

    // NOTE Only include `content` if avialable in binderData
    // don't want to accidentally override
    let content = binderData.content ? { content: binderData.content } : {}

    let formatStructure = {
      formatStructure: binderData.formatStructure,
    }

    let saveData = await saveBinder({
      ...{
        transactionId,
        id: !isNewBinder ? activeBinderId : undefined,
        nickname: binderData.nickname,
        clientName: binderData.clientName,
        closingDates: {
          category: binderData.closingDates?.category || "",
          closingDate: binderData.closingDates?.closingDate || "",
          signingDate: binderData.closingDates?.signingDate || "",
        },
        partyNames: binderData.partyNames || [],
        binderType: binderData.binderType,
        templateId:
          binderData.templateId === "default"
            ? undefined
            : binderData.templateId,
        title: binderData.title,
        customCoverPageId: binderData.customCoverPageId,
        workingGroupDocumentId: binderData.workingGroupDocumentId,
      },
      ...content,
      ...formatStructure,
    })

    if (saveData) {
      onSuccess(saveData)
    }

    return saveData
  }

  return { save, isLoading, error }
}
