import * as React from "react"

import {
  createClosingBinderIndexPreview,
  getBinderIndexPreview,
} from "helpers/api"

const INITIAL = { loading: true, data: null }

let POLLER

let clear = () => {
  clearInterval(POLLER)
  POLLER = null
}

export function useBinderIndexPreview(binderId) {
  let [previewId, setPreviewId] = React.useState(null)
  let [result, setResult] = React.useState(INITIAL)

  React.useEffect(() => {
    async function create() {
      try {
        let data = await createClosingBinderIndexPreview(binderId)
        setPreviewId(data.uuid)
      } catch (e) {
        setResult({ loading: false, error: e, data: null })
      }
    }

    create()
  }, [binderId])

  React.useEffect(() => {
    async function call() {
      try {
        let data = await getBinderIndexPreview(previewId)
        if (data.index_pdf && data.cover_page_pdf) {
          setResult({ loading: false, data })
        }
      } catch (e) {
        setResult({ loading: false, error: e, data: null })
      }
    }

    if (previewId && !result.data && !POLLER) {
      POLLER = setInterval(call, 1000)
    } else if (POLLER && result.data) {
      clear()
    }

    return () => clear()
  }, [previewId, result])

  return result
}
